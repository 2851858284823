@import "default.css" all;
#scene{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.btn_new{
    max-width: 255px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    display: block;
    text-align: center;
    border-radius: 30px;
    background-color: #2f87e2;
    color: #fff;
    font-size: 15px;
    font-family: 'latolight';
    margin: 0 auto;
    z-index: 100;
    position: relative;

}
.bg_transparent{
    background-color: transparent;
    border: 1px solid #fff;
}
a.btn_new.bg_transparent:hover {
    background-color: #ffffff1a;
}

.color2f{
    color: #2f87e2;
}

.title_main{
    font-family: "latobold";
    font-size: 22px;
    color: #fff;
    text-transform: uppercase;
}
.window__main{
    width: 100%;
    overflow: hidden;
    left: 0;
    position: relative;
    background-color: #131313;
}
html{
    background-color: #131313;
}
.window__body{
    height: 100%;
    background: url(../img/window__main.jpg) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    -webkit-transition: 0.7s all ease-out;
    -moz-transition: 0.7s all ease-out;
    -ms-transition: 0.7s all ease-out;
    -o-transition: 0.7s all ease-out;
    transition: 0.7s all  ease-out;
}

.grab{
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.window__mobile{
    display: none;
}

.scroll__window{
    float: left;
    position: relative;
    overflow: hidden;
    display: none;
}

.window__overlay{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 100;
    touch-action: none;
}

/* window one */

.window__one{
    width: 100%;
    height: 100%;
}
.title__h3_main{
    font-size: 52px;
    font-family: "latobold";
    color: #fff;
    text-transform: uppercase;
    line-height: 100%;
    text-align: center;
    letter-spacing: 4px;
    margin-bottom: 7px;
    position: relative;
    z-index: 2;
}

.window__one__text{
    font-size: 18px;
    font-family: "latolight_italic";
    color: #fff;
    font-weight: bold;
    line-height: 100%;
    text-align: center;
    margin-bottom: 35px;
    position: relative;
    z-index: 2;
}
.window__one__text span{
    font-family: "latobold";
    text-transform: uppercase;
}
.center__absolute{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 153px;
    margin: auto;
}
/*.center__absolute:before{*/
    /*content: '';*/
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*margin: auto;*/
    /*background: url(../img/main__logo.png) center;*/
    /*width: 195px;*/
    /*height: 215px;*/
/*}*/


/* window two*/
.window__two{
    overflow: visible;
}
.window__two .title_main {
    max-width: 530px;
    margin: 0 auto
}

.window__two__text p{
    font-size: 15px;
    color: #fff;
    line-height: 1.6;
    max-width: 547px;
    font-family: 'latolight';
    text-align: center;
    margin: 20px auto 0;
    position: relative;
}

.window__two .container{
    height: 100%;
    position: relative;
}

.two__absolute {
    z-index: 3;
}
.two__img__one{
    position: absolute;
    bottom: 0;
    z-index: 10;
     -webkit-transition: 0.4s all ease-out;
    -moz-transition: 0.4s all ease-out;
    -ms-transition: 0.4s all ease-out;
    -o-transition: 0.4s all ease-out;
    transition: 0.4s all  ease-out;
}
.two__img__two{
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: 0.5s all ease-out;
    -moz-transition: 0.5s all ease-out;
    -ms-transition: 0.5s all ease-out;
    -o-transition: 0.5s all ease-out;
    transition: 0.5s all  ease-out;
    z-index: 5;
}
.two__img__three{
    position: absolute;
    bottom: 0;
    right: 10%;
    -webkit-transition: 0.8s all;
    -moz-transition: 0.8s all;
    -ms-transition: 0.8s all;
    -o-transition: 0.8s all;
    transition: 0.8s all;
    z-index: 3;
}

.two__img__four{
    position: absolute;
    bottom: 20%;
    left: -35%;
    -webkit-transition: 0.8s all;
    -moz-transition: 0.8s all;
    -ms-transition: 0.8s all;
    -o-transition: 0.8s all;
    transition: 0.8s all;
    z-index: 1;
}


/* window two end*/
.window__three__text {
    margin-bottom: 50px;
}

.window__three__text p{
    font-size: 15px;
    font-family: "latolight";
    line-height: 1.333;
    color: #fff;
}
.window__three__text .title_main{
    margin-bottom: 20px;
    position: relative;
}
.window__three__text .title_main:before{
    content: '';
    position: absolute;
    top: 0;
    left: -50px;
    font-size: 74px; 
    font-family: 'latoblack';
    color: rgba(255, 255, 255, 0.031);
    text-transform: uppercase;
}
.three__absolute{
    counter-reset: heading; 
    z-index: 2;
}
.window__three__text .title_main:before {
    counter-increment: heading;
    content: "0" counter(heading);
}

.three__img{
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-transition: 0.4s all ease-out;
    -moz-transition: 0.4s all ease-out;
    -ms-transition: 0.4s all ease-out;
    -o-transition: 0.4s all ease-out;
    transition: 0.4s all  ease-out;
    z-index: 1;
}

.canvas__smoke{
    position: absolute;
    top: 0;
    right: 0;
}
#canvas__smoke__img{
    display: none;
}
/* window three  */





/* window four*/

.absolute{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;

}
.window__four .title_main{
    margin-bottom: 20px;
}
.window__four p{
    color: #fff;
    font-size: 13px;
    font-family: 'latolight';
    width: 48%;
    float: right;
    line-height: 1.462;
    margin-bottom: 25px;
}
.window__four p:first-child{
    float: left;
    height: 300px;
}

.four__absolute {
    z-index: 1;
}
.four__img__one{
    position: absolute;
    bottom: 0;
     -webkit-transition: 0.5s all ease-out;
    -moz-transition: 0.5s all ease-out;
    -ms-transition: 0.5s all ease-out;
    -o-transition: 0.5s all ease-out;
    transition: 0.5s all  ease-out;
    z-index: 7;
}
.four__img__two{
    position: absolute;
    bottom: 0;
    -webkit-transition: 0.7s all ease-out;
    -moz-transition: 0.7s all ease-out;
    -ms-transition: 0.7s all ease-out;
    -o-transition: 0.7s all ease-out;
    transition: 0.7s all  ease-out;
    z-index: 5;
}
.four__img__three{
    position: absolute;
    bottom: 0;
    right: 10%;
    -webkit-transition: 0.8s all;
    -moz-transition: 0.8s all;
    -ms-transition: 0.8s all;
    -o-transition: 0.8s all;
    transition: 0.8s all;
    z-index: 3;
}

/* window five */
.window__five .container{
    position: relative;
    height: 100%;
}
.five__absolute{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
    height: 458px;
    max-width: 366px;
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 20px;
    width: 96%;
}
.five__absolute:before,
.five__absolute:after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    z-index: 1;
}
.five__absolute:before{
    top: 0;
    left: 0;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    border-color: transparent;
}
.five__absolute:after{
    bottom: 0;
    right: 0;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    border-color: transparent;
}
.five__absolute.scrollBorder:before{
    transition: width 0.5s ease-out, height 0.5s ease-out 0.5s;
}
.five__absolute.scrollBorder:after{
    transition: border-color 0s ease-out 1s, width 0.5s ease-out 1s, height 0.5s ease-out 1.5s;
}
.five__absolute.scrollBorder:before,
.five__absolute.scrollBorder:after{
    width: 100%;
    height: 100%;
    border-color: #fff;
}

.five__img{
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-transition: 0.5s all ease-out;
    -moz-transition: 0.5s all ease-out;
    -ms-transition: 0.5s all ease-out;
    -o-transition: 0.5s all ease-out;
    transition: 0.5s all  ease-out;
    z-index: 1;
}
.main__contact__info *{
    color: #fff;
    font-size: 15px;
    font-family: "latolight";
}
.main__contact__info a{
    display: block;
    line-height: 1.467;
    position: relative;
    z-index: 100;
}
.main__contact__info li{
    height: 40px;
    margin-top: 30px;
    padding-left: 50px;
    position: relative;
}
.main__contact__info li:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center;
    width: 36px;
}
.main__contact__info li:not(.main__contact__adress) a{
    line-height: 40px;
}
.main__contact__info li.main__contact__skype:before{
    background-image: url(../img/main__contact__skype.png);
    height: 33px;
}
.main__contact__info li.main__contact__adress:before{
    background-image: url(../img/main__contact__adress.png);
    height: 44px;
}
.main__contact__info li.main__contact__phone:before{
    background-image: url(../img/main__contact__phone.png);
    height: 35px;
}
.main__contact__info li.main__contact__fax:before{
    background-image: url(../img/main__contact__fax.png);
    height: 30px;
}
.main__contact__info li.main__contact__email:before{
    background-image: url(../img/main__contact__email.png);
    height: 27px;
}



/* second-screen */

.h4-tabs{
    color: #131419;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 15px;
    /*white-space: nowrap;*/
    text-align: center;
}

i.icon{
    background: url(../img/icon.png) no-repeat;
    display: block;
    width: 59px;
    height: 52px;
    margin: 0 auto;
    background-position: center!important;
}
i.icon-2{
    background: url(../img/icon2.png) no-repeat;
}
i.icon-3{
    background: url(../img/icon3.png) no-repeat;
}
i.icon-4{
    background: url(../img/icon4.png) no-repeat;
}
i.icon-5{
    background: url(../img/icon5.png) no-repeat;
}
i.icon-6{
    background: url(../img/icon6.png) no-repeat;
}
i.icon-7{
    background: url(../img/icon7.png) no-repeat;
}
i.icon-8{
    background: url(../img/icon8.png) no-repeat;
}
i.icon-9{
    background: url(../img/icon9.png) no-repeat;
}
i.icon-10{
    background: url(../img/icon10.png) no-repeat;
}
i.icon-11{
    background: url(../img/icon11.png) no-repeat;
}
i.icon-12{
    background: url(../img/icon12.png) no-repeat;
}
i.icon-13{
    background: url(../img/icon13.png) no-repeat;
}
i.icon-14{
    background: url(../img/icon14.png) no-repeat;
}
i.icon-15{
    background: url(../img/icon15.png) no-repeat;
}
i.icon-16{
    background: url(../img/icon16.png) no-repeat;
}
i.icon-17{
    background: url(../img/icon17.png) no-repeat;
}
i.icon-18{
    background: url(../img/icon18.png) no-repeat;
}
.tabs-s > span {
    position: relative;
    line-height: 60px;
}
.tabs-s > span:before{
    content: '';
    position: absolute;
    top: -6px;
    left: -28px;
    bottom: 0;
    background: url(../img/tab_main.png) 0 0 no-repeat;
    height: 28px;
    width: 21px;
}



.p-tab{
    color: #666;
    font-size: 12px;
    font-family: 'latomedium';
    line-height: 16px;
    text-align: center;
}
.border .btn{
    margin: 44px auto 40px;
}

.bg-blue{
    background-color: #0597cd;
    min-height: 451px;
    padding-top: 180px;
    padding-right: 30px;
    padding-left: 27px;
}
.bg-blue p span{
    font-size: 30px;
    line-height: 30px;
    display: block;
} 
.bg-blue p.text-uppercase{
    font-family: 'latomedium';
    letter-spacing: 1px;
    margin-bottom: 25px;
}


span.h3{
    padding-top: 193px;
    display: block;
    font-size: 60px;
    color: #fff;
    line-height: 48px;
    font-family: 'CGTimes-BoldItalic';
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: bold;
    font-style: italic;
    z-index: 10;
    position: relative;
}

/*four-screen*/

.text-four{
    font-size: 13px;
    font-family: 'latosemibold';
    position: relative;
    margin-top: 60px;
}
.text-four h1{
    font-size: 24px;
    color: #131419;
    letter-spacing: 1px;
    font-family: 'latoregular';
    margin-bottom: 20px;
    font-weight: normal;
    position: relative;
}
.text-four h1:before{
    content: '';
    position: absolute;
    top: -4px;
    left: -50px;
    background: url(../img/iconH1.png) center no-repeat;
    width: 32px;
    height: 30px;
}
.text-four h3{
    font-family: 'latoregular';
    font-size: 13px;
    line-height: 18px;
    color: #0597cd;
    font-weight: normal;
}
.text-four p{
    color: #666;
    font-family: 'latosemibold';
    margin: 10px 0;
    line-height: 16px;
}
.text-four h4{
    color: #000;
    margin: 10px 0;
}
.list {
    margin: 4px 0;
}
.list p{
    margin: 0;
    padding-left: 10px;
    position: relative;
}
.list p:before{
    content: '-';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}


/* FAQ */ 
.faq {
    margin-bottom: 40px;
}

.faq h2{
    margin-bottom: 45px;
}

.accordion > div {
    margin-bottom: 20px;
}
.faq .title-accordion{
    height: 44px;
    line-height: 44px;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'latobold';
    padding-left: 20px;
    background-color: #7162a5;
    letter-spacing: 1px;
}
.faq .accordion .clearfix{
    margin-bottom: 25px;
}

.faq .item *{
    padding-left: 20px;
}
.faq .item span{
    height: 40px;
    line-height: 40px;
    background-color: #fafafa;
    display: block;
    color: #8288a5;
    display: block;
    font-family: 'Helvetica';
    cursor: pointer;
    position: relative;
}
.faq .item span:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto;
    border-top: 1px solid #b7b7b8;
    border-right: 1px solid #b7b7b8;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    height: 8px;
    width: 8px;
}
.faq .item span.open{
    background-color: #e6e6e6;
    border-color: #e6e6e6;
}
.faq .item span.open:before{
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    border-color: #fff;
}
    
.faq .item p{
    visibility: hidden;
    opacity: 0;
    height: 0;
    color: #666;
    font-size: 14px;
    font-family: 'latoitalic';
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    margin-top: -1px;

}
.faq .item span.open + p{
    display: block;
    padding-top: 15px;
    padding-bottom: 30px;
    padding-right: 20px;
    height: auto;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    margin-top: 0px;
}

.faq .item span,
.faq .item p{
    border: 1px solid #ebebeb;
    border-width: 1px 1px 0px;
}

.faq .item:last-child span,
.faq .item:last-child p{
    border-bottom-width: 1px;
}


/*registraton */
.registraton {
    margin-bottom: 60px;
}

.registraton p{
    margin-top: 30px;
    margin-bottom: 30px;
    color: #454546;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    font-family: 'latomedium_italic';
    text-align: center;
}
.registraton .tabs-s{
    cursor: default;
}

.registraton ul{
    display: block;
    width: 100%;
    clear: both;
    border: 1px solid #e6e6e6;
    border-width: 0 1px 0 1px;
    margin-bottom: 16px;
}
.registraton li{
    height: 40px;
    padding-left: 70px;
    padding-top: 12px;
    color: #666;
    font-size: 12px;
    font-family: 'latomedium';
    border-bottom: 1px solid #e6e6e6;
    position: relative;
}
.registraton li:before{
    content: '';
    position: absolute;
    top: 12px;
    left: 45px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 1px solid #36aeda;
}
.registraton li:after{
    content: '';
    position: absolute;
    top: 16px;
    left: 51px;
    width: 4px;
    height: 7px;
    border: 1px solid #36aeda;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.registraton .btn{
    color: #7cc1db;
    font-size: 12px;
    font-family: 'Times-Italic';
    margin: 0 auto;
}



/*form-reg-seam */

.form-reg-seam {
    max-width: 480px;
    margin: 0 auto;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 60px;
}
.form-reg-seam .form-group,.form-reg-emp .form-group{
    margin-bottom: 20px;
}
.form-reg-seam input,.form-reg-emp input,.form-reg-emp select{
    float: right;
    max-width: 340px;
    width: 100%;
    height: 33px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    padding-left: 15px;
    font-family: 'latomedium_italic';
    font-size: 13px;
    color: #888;
}
.form-reg-seam input{
    max-width: 280px;
}

.form-reg-seam p,.form-reg-emp p{
    float: left;
    height: 30px;
    line-height: 30px;
    color: #0d0d0d;
    font-size: 16px;
    letter-spacing: 1px;
}

.form-reg-seam button[type="submit"]{
    line-height: 35px;
    height: 35px;
    font-family: 'latoregular';
    max-width: 243px;
} 

.form-reg-seam button.btn-light{
    max-width: 185px;
    height: 33px;
    line-height: 33px;
}


/* form-reg-emp */

.form-reg-emp {
    margin-top: 50px;
    margin-bottom: 60px;
    margin-right: 45px;
    margin-left: 45px;
    overflow: hidden;
}
.form-reg-emp .h3-span{
    text-align: left;
    font-size: 18px;
    color: #0d0d0d;
    margin-bottom: 25px;
}
.form-reg-emp .btn-r-l{
    background-color: rgb(66, 134, 182);
    color: #fff;
    max-width:  187px;
    width: 100%;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
    cursor: pointer;
}
.form-reg-emp input[type='file']{
    display: none;
}

.add-input{
    display: block;
    float: right;
    clear: both;
/*    margin-top: 10px;*/
    color: #507299;
    font-size: 13px;
    font-family: 'latosemibold';
    cursor: pointer;
    position: relative;
}
.add-input:before{
    content: '';
    position: absolute;
    top: -3px;
    left: -22px;
    bottom: 0;
    margin: auto;
    background: url(../img/add_input.png) no-repeat;
    width: 15px;
    height: 15px;
}
.form-reg-emp .row:nth-of-type(1){
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
}

.form-reg-emp textarea{
    height: 160px;
    resize: none;
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    color:#888;
    font-size: 13px;
    font-family: 'latomedium_italic';
    padding: 10px 15px;
}
.form-reg-emp .row:last-child{
    margin-top: 50px;
}
.form-reg-emp .row:last-child .h3-span{
    margin-bottom: 10px;
}
.form-reg-emp button[type='submit']{
    max-width: 244px;
    margin: 20px 0;
}
.form-reg-emp .mb{
    margin-bottom: 10px;
}
.img-slider{
    margin-bottom: 30px;
}
.img-slider .dz-preview{
    height: auto;
    border-radius: 5px;
    border: 1px solid rgb(230, 230, 230);
    position: relative;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}
.img-slider img{
    height: 100%;
    width: auto;
}
.dz-remove{
    position: absolute;
    top: -10px;
    right: 0;
    cursor: pointer;
    background-color: #f00;
    width: 11px;
    height: 11px;
}

.form-reg-emp .table th{
    height: 33px;
    line-height: 33px;
    background-color: #4286b6;
    text-align: left;
    padding-left: 24px;
}
.form-reg-emp .table input{
    width: 100%!important;
    float: none!important;
    background: transparent;
    padding-right: 0!important;
}
.upload{
    color: #57a7ca;
    font-size: 12px;
    font-family: 'Times-Italic';
    background: none;
}
/* contacts */

.contacts {
    background: url(../img/bg_contacts.jpg) center no-repeat;
    background-size: cover;
    min-height: 637px;
    height: auto;
    padding: 20px 0px;
}

.contacts h1{
    opacity: 0.17;
    padding-top: 40px;
}

.contacts .contacts-info li{
    font-size: 16px;
    line-height: 20px;
    color: #eaeaea;
    font-family: 'latomedium';
    margin-bottom: 20px;
    padding-left: 35px;
    position: relative;
}
.contacts .contacts-info li a{
    color: #eaeaea;
    font-family: 'latomedium';
}
.contacts .contacts-info li a:hover{
    color: #131419;
}
.contacts .contacts-info li:before,.contact-btn:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: url(../img/contact-i.png) no-repeat;
}
.contacts .contacts-info li.i-tel:before{
    width: 22px;
    height: 22px;
    background-position: -5px -5px;
}
.contacts .contacts-info li.i-email:before{
    width: 22px;
    height: 17px;
    background-position: -5px -37px;
}
.contacts .contacts-info li.i-site:before{
    width: 22px;
    height: 22px;
    background-position: -5px -64px;
}
.contacts .contacts-info li.i-skype:before{
    width: 22px;
    height: 22px;
    background-position: -5px -96px;
}
.contacts .contacts-info li.i-map:before{
    width: 16px;
    height: 22px;
    background-position: -5px -128px;
}

.contacts .contacts-info{
    margin-top: 66px;
}
.contacts h2{
    margin-bottom: 30px;
}
.contact-btn{
    color: #fff;
    font-size: 14px;
    line-height: 41px;
    letter-spacing: 1px;
    font-family: 'latoitalic';
    text-align: center;
    border: 1px solid #fff;
    max-width: 220px;
    width: 100%;
    height: 41px;
    display: block;
    border-radius: 30px;
    position: relative;
    margin-bottom: 20px;
}

.contact-btn:before{
    width: 24px;
    height: 18px;
    background-position: -5px -160px;
    margin: auto;
    left: 15px
}
.contact-btn:hover {
    background-color: rgb(33, 159, 210);
    box-shadow: 0px 0px 8px 4px rgb(73, 184, 229);
}
.contacts .contact-form{
    border: 1px solid rgb(235, 235, 235);
    border-radius: 5px;
    max-width: 490px;
    width: 100%;
    min-height: 438px;
    background-color: #fff;
    padding: 40px 60px;
    margin: 0 auto;
}
.contact-form .title-h2{
    text-align: center;
    display: block;
    font-size: 20px;
    margin-bottom: 25px;
}
.contact-form p{
    vertical-align: top;
    font-family: 'latomedium';
    font-size: 14px;
    color: #0d0d0d;
    clear: both;
    line-height: 26px;
}
.contact-form input,
.contact-form textarea{
    height: 35px;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 5px;
    background-color: rgb(243, 243, 243);
    max-width: 293px;
    min-width: 80%;
    width: auto;
    float: right;
    margin-bottom: 15px;
    padding: 0px 12px;
}
.contact-form textarea{
    resize: none;
    height: 120px;
}

.map .title-h2{
    display: block;
    margin-top: 50px;
    margin-bottom: 30px;
}
.maps{
    height: 272px;
    width: 100%;
}
.maps iframe{
    width: 100%;
    height: 100%;
}

/* application_form */ 
.general-info,
.personal-info,
.biometrics,
.training-certificates,
.medical-certificates{
    margin-bottom: 45px;
}
.navigator-form-reg{
    margin-bottom: 20px;
}
.navigator-form-reg > .container{
    width: 100%;
    display: table;
}
.navigator-form-reg .tabs-s{
    font-size: 14px;
    text-transform: inherit;
    letter-spacing: 1px;
    border: none;
    border-right: 1px solid #e6e8f1;
    height: 50px;
    line-height: 50px;
}
.navigator-form-reg .tabs-s:last-child{
    border-right: none;
}
.navigator-form-reg .tabs-s:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    border-top: 1px solid rgba(255,255,255,0.2);
    border-right: 1px solid rgba(255,255,255,0.2);
    height: 10px;
    width: 10px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/*.general-info .col-md-3.no-paddingR > div{
    min-height: 407px;
}*/
.general-info div.form,
.personal-info div.form,
.biometrics div.form,
.training-certificates div.form,
.medical-certificates div.form,
.sea-service div.form,
.summary div.form{
    padding: 0px 35px;
}
[data-parent="form-reg"] p{
    float: left;
    max-width: 148px;
    width: 100%;
    font-family: 'latobold';
    font-size: 14px;
    color: #0d0d0d;
    letter-spacing: 1px;
    line-height: 33px;
}
[data-parent="form-reg"] input,
[data-parent="form-reg"] select{
    width: 100%;
    max-width: 380px;
    float: right;
    height: 33px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 16px;
    font-family: 'latomedium_italic';
    font-size: 13px;
    color: #888;
}
[data-parent="form-reg"] textarea{
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 16px;
    font-family: 'latomedium_italic';
    font-size: 13px;
    color: #888;
}
[data-parent="form-reg"] .form-group{
    margin-bottom: 10px;
}
.general-info .w110{
    width: 141px;
}
.general-info .w50{
    max-width: 290px;
    width: 100%;
} 
.general-info .right.w50 {
   max-width: 238px; 
}
.general-info .right.w50 p{
    padding-right: 10px;
    max-width: 97px;
    padding-left: 15px;
}
.general-info .addPhoto input{
    display: none;
}

/*фото news*/

.photo-crop{
    width: auto;
    height: auto;
    border-radius: 50%;
   
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
}
.photo-crop.zIndex{
    z-index: 10;
    cursor: move;
}
#photo-id{
    position: absolute;
    width: 100%;
    height: auto
}

#crop_tool{
    background: rgba(255,255,255,.5);
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #fff;
    z-index: 100;
}
#crop_tool_bg{
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255, 0.5);
    position: absolute;
}
.modal {
    width: 200px;
    height: 200px;
}
.body-cropp{
    position: relative;
    height: 100%;
}

.image-full-div{
    position: fixed;
    top: 10%;
    left: 0;
    right: 0;
    margin: auto;
    width: 500px;
    height: 500px;
    background-color: #fff;
}

.resizer{
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: #000;
}

#top-resize{
    top: 0;
    left: 0;
    cursor: nw-resize;
}
#right-resize{
    top: 0;
    right: 0;
    cursor: ne-resize;
}
#bottom-resize{
    bottom: 0;
    right: 0;
    cursor: se-resize;
}
#left-resize{
    bottom: 0;
    left: 0;
    cursor: sw-resize;
}
/**/
.general-info .addPhoto label{
    background: url(../img/addphoto.png) no-repeat center;
    width: 226px;
    height: 226px;
    display: block;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #f0f0f0;
}
.general-info .addPhoto span{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #666;
    font-size: 14px;
    font-family: 'latolight_italic';
    display: block;
    margin: auto;
    height: 16px;
    text-align: center;
}
.general-info .addPhoto{
    margin-bottom: 60px;
    position: relative;
}
.general-info input::-webkit-input-placeholder {color:#888;font-size: 13px;font-family: 'latomedium_italic';}
.general-info input::-moz-placeholder          {color:#888;font-size: 13px;font-family: 'latomedium_italic';}/* Firefox 19+ */
.general-info input:-moz-placeholder           {color:#888;font-size: 13px;font-family: 'latomedium_italic';}/* Firefox 18- */
.general-info input:-ms-input-placeholder      {color:#888;font-size: 13px;font-family: 'latomedium_italic';}

ul.progress li{
    color: #666;
    font-size: 13px;
    line-height: 25px;
    padding-left: 36px;
    letter-spacing: 1px;
}

ul.progress{
    width: 200px;
    margin: 0 auto;
}

.required{
    color: #c5c5c5;
    font-size: 14px;
    font-family: 'latoitalic';
    letter-spacing: 1px;
    display: block;
    margin-top: 25px;
}

.form-group.w50,.btn-w50{
    width: 50%;
    float: left;
}
.btn-w50 .btn{
    margin-bottom: 0!important;
    max-width: 187px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
}
.form-group.w50 input{
    max-width: 232px;
}
.form-group.w50 p.pl18{
    padding-left: 18px;
    width: auto;
    max-width: 100%;
}

.personal-info input{
    width: 100%;
    max-width: 630px;
}

.personal-info th {
    height: 34px!important;
}
.personal-info .borderRTLR5.no-border {
    margin-top: 20px;
}
.personal-info table input,
.training-certificates table input,
.medical-certificates table input{
    border: none;
    height: 100%;
    width: 100%;
}
.addTR{
    color: #0597cd;
    font-family: 'latosemibold';
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 1px;
    margin-top: 20px;
    display: inline-block;
    position: relative;
    padding-right: 20px;
}
.addTR:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}
[data-parent="form-reg"] button.btn{
    margin-bottom: 40px;
}
.w154{
    max-width: 153px!important;
    margin-left: 5px!important;
    float: left!important;
}
.w154:nth-of-type(1){
    margin-left: 16px!important;
}
.w310{
    max-width: 313px!important;
    float: left!important;
    margin-left: 16px;
}

.biometrics p{
    width: auto;
    max-width: 100%;
}
.biometrics input{
    max-width: 160px;
    margin-right: 10px;
}


.tabs-s b{
    font-size: 15px;
    font-family: 'latoheavy';
    opacity: 0.2;
    display: inline-block;
    margin-left: -12px;
    padding-right: 10px;
}

/* summary */
.summary textarea{
    border: 1px solid rgb(214, 214, 214);
    border-radius: 5px;
    background-color: #fff;
    width: 100%;
    height: 193px;
    resize: none;
    margin-bottom: 25px;
}
.summary p{
    color: #0d0d0d;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    font-family: 'latoitalic';
    max-width: 100%!important;
    margin-bottom: 20px;
    float: none;
}






/* for seamen and for employers*/ 
.top-img{
    height: 668px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 35px;
}
.top-img h1,.top-img h2{
    color: #fff;
    text-shadow: 0px 1px 6px rgb(0, 0, 0);
    letter-spacing: 1px;
    line-height: 48px;
}

.top-img h1{
    font-size: 38px;
    text-transform: uppercase;
}

.top-img h2{
  font-size: 28px;
  font-family: 'latoitalic';
}

.top-img .container{
    position: relative;
}

.top-title {
    position: absolute;
    top: 100px;
    left: 0;
}
.top-title.pRight{
    text-align: right;
}

.top-title .btn{
    margin-top: 15px;
}
.top-title .btn-w{
    max-width: 280px;
    min-height: 50px;
    line-height: 50px;
}
.seo-text-simple-page{
/*    min-height: 440px;*/
    margin-top: 45px;
}
.bg_employers_main{
    min-height: 386px;
}
.bg_img_seamens{
    background: url(../img/bg_img_seamens.jpg) no-repeat;
    background-size: cover;
    min-height: 550px;
}
.bg_img_employers{
    background: url(../img/bg_img_employers.jpg) no-repeat;
    background-size: cover;
    min-height: 450px;
}
.bg_e_crewing{
    background: url(../img/bg_e_crewing.jpg) no-repeat;
    background-size: cover;
    min-height: 800px;
}
.bg_software {
    background: url(../img/bg_software.jpg) no-repeat;
    background-size: cover;
    min-height: 386px;
}
.title-seo{
    position: absolute;
    bottom: 87px;
    right: 30px;
    color: #fff;
    text-align: right;
    letter-spacing: 1px;
}
.title-seo h3{
    font-size: 30px;
    line-height: 30px;
    font-family: 'Times-Italic';
}
.title-seo p{
    font-size: 21px;
    line-height: 30px;
    font-family: 'Times-Italic';   
}

.text-simple-page{
    padding-top: 60px;
}
.text-simple-page h4{
    font-size: 24px;
    color: #131419;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'latoheavy';
    position: relative;
}
.text-simple-page h4:before{
    content: '';
    position: absolute;
    top: -8px;
    left: -50px;
    width: 32px;
    height: 37px;
}
.text-simple-page.icon-seamens h4:before{
    background: url(../img/icon-for-seamen.png) no-repeat center;
}
.text-simple-page.icon-employers h4:before{
    background: url(../img/icon-for-employers.png) no-repeat center;
}
.text-simple-page.icon-ecrewing h4:before{
    background: url(../img/icon-for-ecrewing.png) no-repeat center;
}
.text-simple-page.icon-software h4:before{
    background: url(../img/icon-for-software.png) no-repeat center;
}
.text-simple-page.icon-software{
    margin-left: 70px;
}
.text-simple-page p {
    color: #666;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 18px;
    margin-top: 20px;
}

.text-simple-page .btn{
    max-width: 175px;
    border-radius: 20px;
    height: 41px;
    line-height: 41px;
    margin-top: 25px;
}

/*
.sprite-f {
    width: 6px;
    height: 12px;
    background-position: -5px -5px;
}

.sprite-g_ {
    width: 19px;
    height: 12px;
    background-position: -5px -27px;
}

.sprite-in {
    width: 12px;
    height: 11px;
    background-position: -5px -49px;
}

.sprite-vk {
    width: 18px;
    height: 12px;
    background-position: -5px -70px;
}*/

.roulette{
    height: 800px;
    overflow: hidden;
    position: relative;
}

.item-v,.item-c{
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  width: 220px;
  height: 116px;
  margin-bottom: 50px;
  position: absolute;

}
.item-v{
    top: 0;
    left: 0;
}
.item-c{
    right: 0;
}

.our_agency_slider {
    margin-bottom: 50px;
}

.our_agency_slider .slick-arrow{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    text-indent: -99999px;
    z-index: 100;
    border: 1px solid rgb(177, 212, 227);
    border-radius: 50%;
    background-color: transparent;
    width: 36px;
    height: 36px;
}

.our_agency_slider .slick-arrow:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 15px;
    height: 15px
}

.our_agency_slider div.slick-slide{
    position: relative;
    overflow: hidden;
}
.our_agency_slider div.slick-slide img {
    margin: auto;
}

.our_agency_slider .slick-prev{
    left: -50px;
}
.our_agency_slider .slick-next{
    right: -50px;
}



.our_agency_slider .slick-prev:before{
    border-top: 2px solid #7bbad5;
    border-left: 2px solid #7bbad5;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg) ;
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    
}
.our_agency_slider .slick-next:before{
    border-top: 2px solid #7bbad5;
    border-right: 2px solid #7bbad5;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg) ;
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}


.our_agency_slider .slick-dots{
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
.our_agency_slider .slick-dots button{
    width: 10px;
    height: 10px;
    border: 1px solid rgb(10, 164, 221);
    border-radius: 50%;
    text-indent: -99999px;
    background-color: #fff;
}

.our_agency_slider .slick-dots li{
    display: inline-block;
    margin: 0 2px;
}
.our_agency_slider .slick-dots li.slick-active button{
    background-color: rgb(10, 164, 221);
}

.our_agency h4,
.our_agency p{
    text-align: center;
}

.our_agency p{
    font-family: 'Times-Italic';
    margin: 20px auto;
    max-width: 700px;
    font-size: 14px;
    color: #454546;
}

.our_agency .text-simple-page {
    padding-top: 45px;
}



@media(max-width: 991px){
    .scroll__window{
        float: none;
    }     
    .window__overlay{
        display: none;
    }
    .center__absolute{
        position: relative;
        margin: 50px 0;
    }
    .no_absolute{
        position: relative!important;
    }
    .title__h3_main{
        font-size: 40px;
    }
    .scroll__window .container{
        padding: 30px 10px;
    }
    .five__absolute,
    .two__absolute,
    .three__absolute {
        z-index: 100;
        left: 0;
    }
    .five__absolute:before,
    .five__absolute:after{
        width: 100%;
        height: 100%;
        border-color: #fff;
    }
    .five__img img,
    .three__img img{
        width: 100%;
    }
    .three__img{
        position: relative;
    }
    .window__two img,
    .window__four img{
       display: none;
    }
    .three__img{
        width: 100%;
    }
    .window__four p{
        width: 100%;
        height: auto!important;
        margin-bottom: 10px;
    }
    .window__three__text{
        text-align: center;
    }
    section.window__one.scroll__window {
        padding-top: 70px;
        padding-bottom: 120px;
    }
}
@media(max-width: 767px){
    .main__logo{
        margin-top: 0;
    }
    .title__h3_main{
        font-size: 20px;
        letter-spacing: 1px;
        margin-bottom: 15px;
    }
    .title_main{
        font-size: 18px;
    }
    .window__one__text{
        font-size: 15px;
    }
    .window__one__text span{
        display: block;
    }
    .scroll__window .container{
        width: 100%;
        text-align: center;
    }
    .window__one{
        display: none!important;
    }
    .window__mobile{
        position: relative;
        display: block;
    }
    .window__mobile .container{
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .window__mobile__title{
        font-size: 17px;
        margin-bottom: 10px;
    }
    .window__mobile__login{
        margin-top: 20px;
    }
    .window__mobile__reset{
        text-transform: uppercase;
        display: block;
        text-align: center;
        margin-top: 10px;
    }
    .window__mobile__login input{
        width: 90%;
        max-width: 300px;
        display: block;
        margin: 0 auto;
        height: 34px;
        border-radius: 4px;
        border: 1px solid #fff;
        background: #141414;
        margin-bottom: 16px;
        color: #fff;
        padding-left: 10px;
    }
    .window__mobile__register{
        position: relative;
        top: 45px;
        left: 0;
        width: 100%;
        text-align: center;
    }
    .window__mobile__register p{
        color: #fff;
        margin-bottom: 10px;
    }
    .window__mobile .alert-danger.main-page {
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90%;
        max-width: 300px;
    }

}
