/* default */
input:hover {
    box-shadow: inset 4px 3px 8px 0px rgba(0, 0, 0, 0.05);
}

.main__menu__login input {
    transition: box-shadow 0.3s ease-in-out;
}
.ribbon {
    position: absolute;
    right: -5px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px;
    text-align: right;
}
.ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#F70505 0%, #8F0808 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
}
.ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #8F0808;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F0808;
}
.ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #8F0808;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #8F0808;
}
/* плавное изменение прозрачности  placeholder-а при фокусе */
input::-webkit-input-placeholder       {color:#d6d5ea !important; opacity: 1; transition: opacity 0.3s ease;}
input::-moz-placeholder                {color:#d6d5ea !important; opacity: 1; transition: opacity 0.3s ease;}
input:-moz-placeholder                 {color:#d6d5ea !important; opacity: 1; transition: opacity 0.3s ease;}
input:-ms-input-placeholder            {color:#d6d5ea !important; opacity: 1; transition: opacity 0.3s ease;}
input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

textarea::-webkit-input-placeholder       {color:#d6d5ea !important; opacity: 1; transition: opacity 0.3s ease;}
textarea::-moz-placeholder                {color:#d6d5ea !important; opacity: 1; transition: opacity 0.3s ease;}
textarea:-moz-placeholder                 {color:#d6d5ea !important; opacity: 1; transition: opacity 0.3s ease;}
textarea:-ms-input-placeholder            {color:#d6d5ea !important; opacity: 1; transition: opacity 0.3s ease;}
textarea:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

.flag {
    bottom: 2px;
    position: relative;
    opacity: 0.3;
    transition: opacity 0.5s ease;
}
.flag:hover {
    opacity: 1.0;
}

*{
    margin: 0;
    padding: 0;
    line-height: 100%;
    outline: none;
}
body{
    font-family: 'latoregular';
}
.container{
    max-width: 1180px;
    width: 96%;
    padding-left: 10px;
    padding-right: 10px;
}
.scroll{
    overflow-y: scroll;
}
.scroll-text::-webkit-scrollbar {
    width: 1em;
}
.scroll-text::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.scroll-text::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}
.clear{
    clear: both;
}
b {
    font-weight: normal;
    position: relative;
}
h1,h2,h3,h4,h5,h6{
    font-family: 'latobold';
    margin: 0;
    font-weight: normal;
}
a,
a:hover,
a:focus{
    text-decoration: none;
}
a:hover:not(.link), a:focus(.link){
    color: #fff;
}
button{border: none;}
.text-uppercase{text-transform: uppercase;}
.text-center{text-align: center!important}
ul{list-style: none;}
.left{float: left;}
.right{float: right;}
.center{margin: 0 auto;}
.no-padding{padding: 0!important;}
.no-paddingL{padding-left: 0!important}
.no-paddingR{padding-right: 0!important;padding-left: 20px;}
.no-margin{margin: 0!important;}
.no-marginL{margin-left: 0!important}
.no-border{border: 0!important}
table{width: 100%}
.cr13{color: #131419!important}
.cE6{color: #e6e8f1!important}
.bgFa{background-color: #fafafa;}
.bgFf{background-color: #fff;}
.bgF0{background-color: #f0f0f0;}
.bgF7{background-color: #f7f7f7;}
.bg05{background-color: #0597cd!important;}
.bg42{background-color: #446b87!important;}
.bcFF{border-color: #ffcb68!important;}
.opacity0{opacity: 0;visibility: hidden;}
.mb2{margin-bottom: 10px;}
.lh16 {
    line-height: 32px!important;
}
.width-auto{
    width: auto;
    display: inline-block;
    height: auto;
    min-height: 24px;
    margin: 0;
}
.pRight{
    left: inherit!important;
    right: 0!important;
}
.overlay,
.overlay-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(0, 0, 0, 0.55),black);
    display: none;
    z-index: 10;
}
.overlay-modal{
    background: url(/img/spin.svg) rgba(0,0,0,0.5) no-repeat center;
    z-index: 1000;

}
span.fdata {
    width: 164px;
    margin: auto;
    transform: translateY(57vh) translateX(1ch);
    display: block;
    color: #dedede;
    font-size: 24px;
}


.mx388{
    max-width: 388px;
    width: 100%;
}
.title-h1{
    text-align: center;
    color: #fff;
    font-size: 48px;
    letter-spacing: 5px;
    margin-bottom: 20px;
    padding-top: 45px;
}
.title-h2{
    text-align: center;
    font-size: 24px;
    font-family: 'latoheavy';
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
}

/* табы */
.tabs-s{
    border: 1px solid #e6e8f1;
    /*background: linear-gradient(to bottom, #4CAF50 0%,#2b852f 100%);*/
    background-color: #57a7ca;
    max-width: 388px;
    width: 100%;
    height: 60px;
    color: #fff;
    font-size: 16px;
    font-family: 'latoheavy';
    text-transform: uppercase;
    line-height: 60px;
    letter-spacing: 2px;
    cursor: pointer;
    position: relative;
    text-align: center;
}
.tabs-s.active,
.tabs-s:hover{
    background-color: #0597cd;
}

.navigation .tabs-s:not([data-parent="crewing"]){
    background: linear-gradient(to bottom, #6f96a7 0%,#57a7ca 100%);
    color: #ffffff;
}
.navigation .tabs-s:hover{
    color: #fff;
}
.navigation .tabs-s.active{

}
[data-tabs]{
    display: none;
}
.active[data-tabs]{
    display: block;
}
/*end */
.col-2{
    width: 20%;
    float: left;
}
.col-3{
    width: 25%;
    float: left;
}
.col-4 {
    width: 33.3333%;
    float: left;
}

.col-1-4{
    display: table-cell;
    width: auto;
    padding: 0 5px;
}

.borderR5{
    border-radius: 5px;
    background: #fff;
    padding-top: 38px!important;
    border-color: rgba(0, 0, 0, 0.16);
    border-style: inset;
    border-width: 1px;
    box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.02);
}

.mt-3{
    margin-top: 30px !important;
}

.instant-application {
    max-width: 300px;
    margin: auto;
}

.instant-application input,
.instant-application select{
    border: 1px solid rgba(214, 214, 214,0.88)!important;
    border-radius: 5px;
    padding: 0px 12px;
    margin-top: 20px;
    font-size: 13px;
    color: #666;
    line-height: 30px;
    height: 30px!important;
    letter-spacing: 0.5px;
    font-family: 'latoregular';
    width: 100%!important;
    max-width: 100%!important;
    background: rgba(240, 248, 255, 0.31);
    background: -moz-linear-gradient(top, rgba(32, 8, 64,1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(top, rgba(240, 248, 255, 0.31) 0%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(240, 248, 255, 0.31) 0%,rgba(255,255,255,1) 100%);
}


.PLPR{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
}
.borderRTLR5{
    border-radius: 5px 5px 0 0;
    border: 1px solid #e6e6e6;
    /*overflow: hidden;*/
    border-top: none;
    margin-bottom: 30px!important;
}

.form-group:before,
.form-group:after{
    content: '';
    display: table;
    clear: both;
}


.table th[data-name="subtype"]{
    width: 200px;
}
.table thead{
    background-color: #57a7ca;
}
.table th{
    color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
    font-family: 'latosemibold';
    height: 45px;
    border: 1px solid #e6e6e6;
    border-width: 0 1px 1px 1px;
    text-align: center;
    padding: 0 5px;
    font-weight: normal;
}
.table td{
    min-height: 40px;
    height: 40px;
    color: #666;
    font-size: 13px;
    font-family: 'latoregular';
    letter-spacing: 1px;
    border: 1px solid #e6e6e6;
    position: relative;
}


.table__educt th {
    border: none;
}

.table__educt td:first-child{
    border-left: none;
}
.table__educt tr:last-child td{
    border-bottom: none;
}

/*breadcrumb*/
.breadcrumb {
    min-height: 45px;
}
.breadcrumb li{
    display: inline-block;
    line-height: 45px;
    padding-right: 35px;
    background: url(../img/breadcrumb.png) 92% center no-repeat;
}
.breadcrumb li:last-child{
    background: none;
}
.breadcrumb * {
    font-family: 'latolight';
    font-size: 13px;
    color: #a5a5a5;
}
.breadcrumb a{
    color: #0597cd;
}
.btn {
    background-color: rgb(5, 151, 205);
    max-width: 278px;
    width: 100%;
    height: 51px;
    border-radius: 30px;
    display: block;
    text-align: center;
    letter-spacing: 1px;
}
.btn:hover{
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.46)
}
.btn-w {
    color: #fff;
    font-size: 18px;
    font-family: 'latosemibold';
    line-height: 43px;
    height: 43px;
    max-width: 222px;
    margin: 0 auto;
}
.btn_divider {
    display: block;
    width: 100%;
    position: relative;
    height: 100px;
    z-index: -1;
}
.btn-light{
    background-color: rgb(87, 167, 202);
}
.btn-c{
    background-color: #21608d!important;
}
.btn-lc{
    background-color: #4286b6!important;
}
.btn-slider .btn{
    color: #7cc1db;
    font-size: 12px;
    font-family: 'Times-Italic';
}
.btn-xs {
    width: 100px;
    height: 33px !important;
    line-height: 33px !important;
    font-size: 16px !important;
}

.btn span:not(.no-span){
    color: #fff;
    font-size: 20px;
    display: block;
    font-family: 'latosemibold';
    padding-top: 12px;
}


header.fixed{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    display: none;
}
/*
.mob{
    position: fixed!important;
    background: #37383d!important;
    top: 0!important;
    left: 0;
    width: 100%!important;
    height: 100%!important;
    z-index: 10;
}
.mob.menu li {
    margin: 10px 0!important;
    text-align: right;
}
*/
.table th[data-name="gt"],
.table th[data-name="engine_power"]{
    min-width: 70px;
    width: 70px;
    max-width: 70px;
}
.table th[data-name="company"]{
    min-width: 110px;
}


/* select */
select:not(.ui-datepicker-month,.ui-datepicker-year) {
    display: block;
}



select{
    width: 100%;
    border: 1px solid rgba(214, 214, 214,0.5);
    height: 32px;
    line-height: 32px;
    max-width: 216px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 0px 12px;
    font-size: 13px;
    color: #666;
    line-height: 25px;
    letter-spacing: 0.5px;
    font-family: 'latoregular';
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select:not(.date):not(.month):not(.year){
    background: url(../img/select_arrow.png) #fff 96% center no-repeat;
}
select:last-child{
    margin-bottom: 0px;
}
.select > span{
    display: block;
    line-height: 32px;
}
.select > div{
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    border-radius: 5px;
}
.select > div a{
    display: block;
    width: 100%;
}
.select:hover > div,
.select *:hover > div{
    display: block;
}



/*select{
    display: none;
}*/




button.delete {
    background: url(/img/wastebin.svg) center no-repeat;
    background-size: 26px;
    display: block;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.documents td button.delete:hover {
    background-size: 28px;
    filter: drop-shadow(2px 4px 6px grey);
    background-color: crimson;
}

.h3-span {
    font-size: 15px;
    color: #131419;
    font-family: 'latosemibold';
    letter-spacing: 1px;
    text-align: center;
    display: block;
}
.h2-span {
    font-size: 16px;
    color: #0d0d0d;
    font-family: 'latobold';
    letter-spacing: 1px;
    text-align: left;
    display: block;
    line-height: 25px;
    margin-bottom: 5px;
    margin-top: 5px;
}
/* Style the Image Used to Trigger the Modal */
h2.VesselName {
    display: inline;
    font-size: 18px;
    color: #49aad9;
    font-family: Helvetica;
}

#myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 1.3s;
    object-fit: cover;
}

#myImg:hover {
    transform: scale(1.1);
}

/* The Modal (background) */
.modalW {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modalW-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modalW-content, #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)}
    to {-webkit-transform:scale(1)}
}

@keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}

/* The Close Button */
.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.vanilla-rotate {
    text-decoration: none;
    user-select: none;
    padding: .7em 1.5em;
    outline: none;
    border-radius: 30px;
}
button.l{
    color: #fff !important;
    background: #ff3232 !important;
}
button.r{
    color: #fff !important;
    background: #329932 !important;
}
.l:hover { background: #cc2828; }
.r:hover { background: #2d892d; }

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
    .modalW-content {
        width: 100%;
    }
    h2.VesselName {
        display: inline-block;
        font-size: 15px;
        margin-top: 0px;
        font-style: italic;
    }
    .btn_divider {
        height: 15px !important;
    }

    .vacancy-temp h1 {
        margin-bottom: 0px;
    }
    .link__view__company {
        font-size: 13px;
        margin-top: 8px;
    }
    .vidos {
        position: relative !important;
        top: 440px !important;
    }
    .vcontrol {
        position: relative !important;
        top: 224px !important;
    }
    .changeInfo {
        float: none !important;
    }
    .text-simple-page h4 {
        margin-left: 0 !important;
    }
}
.alert .close {
    position: absolute;
    top: -2px;
    right: 3px;
    font-size: 23px;
    background: transparent;
    color: #000;
}

/* ранг */
.range{
    display: block;
    height: 20px;
    margin-top: 10px;
}
.step-r {
    width: 10px;
    height: 10px;
    background: #000;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}
.main-r{
    width: 100%;
    height: 1px;
    background: #eaeaea;
    display: block;
}


button.note{
    background: url(../img/note.png) center no-repeat;
    display: block;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

table input{
    border: none!important;
    height: 100%!important;
    width: 100%!important;
    text-indent: 10px;
    padding-right: 10px;
}



/* пагинация */
.pagination{
    text-align: center;
    margin: 30px 0;
}
.pagination ul li{
    display: inline-block;
    vertical-align: middle;
    padding: 0px 5px;
}
.pagination a {
    font-family: 'latomedium';
    font-size: 14px;
    color: #666;
    line-height: 20px;
    width: 20px;
    height: 20px;
    display: block;
}
.pagination a.active{
    background-color: #0597cd;
    border-radius: 50%;
    color: #fff;
}


.pagination .prev,.pagination .next{
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #8b8b8b;
    position: relative;
    cursor: pointer;
}
.pagination .prev:before,.pagination .next:before{
    content: '';
    position: absolute;
    top: 0;
    left: 3px;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 7px;
    width: 7px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.pagination .prev:before{
    border-bottom: 1px solid #8b8b8b;
    border-left: 1px solid #8b8b8b;
}
.pagination .next:before{
    border-top: 1px solid #8b8b8b;
    border-right: 1px solid #8b8b8b;
    left: -3px;
}

/* вакансия */
.vacancies .item{
    margin-left: 60px;
    margin-bottom: 15px;
    min-height: 180px;
    border: 1px solid #f2f2f2;
    padding: 30px 20px 0 36px;
    position: relative;
}
.vacancies .item.hot{
    background-color: #fff1e1;
}
.vacancies .item{
    min-height: 110px;
}
.vacancies .item:hover{
    background-color: #fbfbfb;
}
.vacancies .item:hover .info-company,
.vacancies .item:hover .logo-company{
    display: block;
}

.vacancies .item:before,
.vacancies .item:after{
    content: '';
    position: absolute;
    top: 0;
    margin: auto;
}

.vacancies .item:not(.no-before):before{
    bottom: 0;
    left: -50px;
    background: url(../img/vacancy.png) center no-repeat;
    height: 32px;
    width: 28px;
}
.vacancies .item.hot:before{
    background: url(../img/hot_vac.png) center no-repeat;
    height: 39px;
    width: 31px;
    bottom: 33px;
}

.vacancies .item.smart.hot:before{
    background: url(../img/hot_smart_vac.png) center no-repeat;
    height: 46px;
    width: 37px;
    left: -50px;
}
.vacancies .item.smart:not(.hot):after{
    bottom: -20px;
    left: -46px;
    background: url(../img/smart.png) center no-repeat;
    height: 21px;
    width: 18px;
}
.vacancies .item:last-child{
    margin-bottom: 0;
}

.vacancies dl *{
    display: inline-block;
    font-family: 'latomedium';
    color: #666;
    font-size: 13px;
    letter-spacing: 1px;
}
.vacancies dl.date,.vacancies dl.lstrnk, .vacancies dl.duration,.vacancies dl.area,.vacancies dl.expire {
    padding-left: 20px;
    position: relative;
    margin-bottom: 5px;
}
.vacancies dl.date,.vacancies dl.duration,.vacancies dl.area,.vacancies dl.expire{
    padding-left: 20px;
    position: relative;
    margin-bottom: 5px;
}
.vacancies dl.date:before,
.vacancies dl.lstrnk:before,
.vacancies dl.duration:before,
.vacancies dl.area:before,
.vacancies dl.expire:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 13px;
    width: 13px;
}
.vacancies dl.date:before,
.vacancies dl.expire:before,
.vacancies dl.duration:before,
.vacancies dl.area:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 13px;
    width: 13px;
}
.vacancies dl.lstrnk:before{
    background: url(../img/cpt.png) center no-repeat;
}
.vacancies dl.date:before{
    background: url(../img/calendar.png) center no-repeat;
}
.vacancies dl.duration:before{
    background: url(../img/clock.png) center no-repeat;
}
.vacancies dl.area:before{
    background: url(../img/area.png) center no-repeat;
}
.vacancies dl.expire:before{
    background: url(../img/expired.svg) center no-repeat;
    background-size: 116%;
}

.vacancies ul.info{
    min-width: 102px;
    margin: 10px auto 0px;
    display: inline-block;
}
.vacancies ul.info li{
    position: relative;
    padding-left: 24px;
    font-size: 12px;
    color: #666;
    font-family: 'latomedium';
    letter-spacing: 1px;
}
.vacancies ul.info li:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background: url('../img/eye.png') no-repeat;
    width: 20px;
    height: 12px;
}
.vacancies ul.info li.right:before{
    background: url('../img/people.png') no-repeat;
    width: 14px;
    height: 14px;
}
.vacancies .price{
    top: 16px;
}

.vacancies .title-company,
.vacancies .info-company{
    float: left;
    width: auto;
    min-width: 185px;
}
.vacancies .info-company {
    position: absolute;
    top: 29px;
    left: 50px;
    right: 0;
    margin: 0 auto;
    width: 210px;
}
.vacancies .logo-company{
    margin-top: 2px;
}

.vacancies .btn{
    height: 37px;
    line-height: 37px;
}


.title-vac{
    font-family: 'latosemibold';
    font-size: 16px;
    color: #131419;
    display: inline-block;
    padding-bottom: 4px;
    letter-spacing: 1px;
}
.title-vac:hover{
    color: #000;
}
.vacancies .item .ship{
    color: #0597cd;
    font-size: 14px;
    font-family: 'latoregular';
    display: block;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.price {
    position: absolute;
    top: 50px;
    right: 44px;
    width: 197px;
    text-align: center;
    z-index: 10;
}
.price .price-price{
    font-family: 'latobold';
    font-size: 18px;
    color: #131419;
    letter-spacing: 0.6px;
    padding-bottom: 5px;
    display: block
}
.price .btn{
    max-width: 197px;
    font-size: 15px;
}
.logo-company dt{
    margin-right: 10px;
}
.logo-company dd{
    font-size: 13px;
    color: #444;
}

.search-vacancies {
    margin: 0 auto 10px;
    padding: 38px 40px 40px;
    max-width: 320px;
}

.search-vacancies label:not(.checkbox){
    color: #0d0d0d;
    font-size: 16px;
    line-height: 25px;
    font-family: 'latoregular';
    border-bottom: 1px solid #eaeaea;
    display: block;
    letter-spacing: 1px;
    margin-bottom: 16px;
    padding-bottom: 2px;
}

input[type="checkbox"]{
    display: none;
}
label.checkbox{
    position: relative;
    padding-left: 31px;
    cursor: pointer;
}
label.checkbox:before,label.checkbox:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;

}
label.checkbox:before{
    width: 18px;
    height: 18px;
    border: 1px solid #2196F3;
    background-color: rgba(3, 169, 244, 0.17);
    border-radius: 4px;
}


input[type="checkbox"]:checked + label.checkbox:after{
    width: 7px;
    height: 12px;
    border-bottom: 2px solid #e84727;
    border-right: 2px solid #e84727;
    transform: rotate(45deg);
    left: 6px;
    top: -5px;
}
input[type="checkbox"]:checked + label.checkbox:before{
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
}
.argee:hover {
    color: #552eca;
}
.search-vacancies label select{
    width: auto;
    height: 22px;
    border-radius: 5px;
    float: right;
    /*    padding: 3px;*/
    line-height: 100%;
}
.search-vacancies input[type="text"]{
    border: 1px solid rgba(214, 214, 214,.5);
    max-width: 93px;
    height: 32px;
    border-radius: 20px;
    font-size: 13px;
    font-family: 'latoregular';
    color: #666;
    padding: 0px 15px;
}

.search-vacancies .form-group {
    position: relative;
    margin-bottom: 25px;
}
.search-vacancies .form-group.slider-group {
    overflow: inherit;
}
/* .search-vacancies .form-group:last-child {
     margin-bottom: 0;
    } */
.form-group hr{
    width: 12px;
    background-color: rgb(234, 234, 234);
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.search-vacancies button{
    color: #fff;
    background-color: rgb(5, 151, 205);
    max-width: 99px;
    height: 33px;
    font-size: 15px;
    line-height: 33px;
}

.search-vacancies button:nth-of-type(1){
    background-color: #84bfd8;
}

.search-vacancies .h3-span{
    font-size: 18px;
    line-height: 50px;
    /*margin-bottom: 20px;*/
    color: #4286b6;
}

.vacancies-tab {
    padding-bottom: 50px;
}
.vacancies-tab .vacancies,
.s-tab .vacancies{
    padding: 22px 40px ;
}
.vacancies-tab .pagination{
    padding-bottom: 40px;
}

.link_vac {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.result .h3-span{
    padding-bottom: 7px;
    text-align: left;
    color: #131419;
    font-size: 18px;
}
.result .h3-span b{
    color: #0597cd;
}
.result .line-b{
    margin: 0px 40px;
    border-bottom: 1px solid #f2f2f2;
}
.sort-by-vac p,
.sort-by-vac ul{
    display: inline-block;
}
.sort-by-vac p{
    font-size: 16px;
    color: #131419;
    font-family: 'latosemibold';
}
.sort-by-vac a{
    color: #666;
}
.sort-by-vac .container-sort:hover ul{
    display: block;
}

.container-sort {
    position: relative;
}

.sort-by-vac ul{
    display: none;
    position: absolute;
    top: 14px;
    left: 0;
    z-index: 1;
}





.vacancy-temp {
    padding-bottom: 50px;
}
.vacancy-temp h1 span{
    color: #0597cd;
}
.vacancy-temp h1 {
    font-family: 'latobold';
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    color: #131419;
}
.infoPerson {
    min-height: 300px;
    padding-bottom: 54px!important;
    color: #777777;
}
.infoPerson dl *{
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
}
.infoPerson dt, .reference-group label {
    color: rgb(13, 151, 205);
    font-family: 'latoregular';
    font-style: italic;
}

.infoPerson dd{
    color: #666;
    font-family: 'latolight';
}


.summary-container {
    max-height: 100px;
    overflow-Y: scroll;
    overflow-x: hidden;
    width: 100%;
}

.summary-container div {
    width: calc(100% + 20px);
    word-break: break-word;
    padding-right: 20px;
}

.summary-container::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.summary-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.summary-container::-webkit-scrollbar-thumb {
    background: #446b87;
}

/* Handle on hover */
.summary-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.job {
    padding-bottom: 35px;
}

.id-job {
    font-size: 12px;
    color: #666;
    letter-spacing: .5px;
    display: inline-block;
    position: absolute;
    padding-top: 4px;
    margin-left: 30px;
}

.published {
    color: #49aad9;
}
.salary-job dd{
    color: #e84727;
}
.job .h2-span{
    margin-top: 25px;
}
.job h1{
    display: inline-block;
}
.job dl {
    letter-spacing: -3px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.job dl *{
    line-height: 18px;
}
.job dt {
    width: 180px;
    display: table-cell;
    vertical-align: top;
}
/*.job .w78{
    width: 78%;
    float: right;
    display: inline-block;
}*/
.job dd{
    font-family: 'latoregular';
    word-break: break-word;
    display: table-cell;
    vertical-align: middle;
}

.share{
    padding: 10px 30px;
    border: 1px solid #e8e8e8;
    border-width: 1px 0;
    margin-bottom: 50px;
    margin-top: 15px;
    position: absolute;
    top: 60px;
    right: 35px;
    margin: 0;
    border: none;
    padding: 0;
}
.share > *,.share li{
    display: inline-block;
    vertical-align: middle;
}
.share p{
    color: #0d0d0d;
    font-family: 'latobold';
    margin-right: 12px;
    letter-spacing: 1px;
}
.share li a{
    border: 1px solid rgb(207, 207, 207);
    border-radius: 50%;
    width: 26px;
    height: 26px;
    position: relative;
    display: block;
    text-align: center;
    line-height: 22px;
    color: #bcbcbc;
    font-size: 16px;
    font-family: 'latosemibold';
    margin-right: 5px;
}
.share li a:hover{
    color: #fff;
    background-color: #0597cd !important;
    border-color: #0597cd;
}
.link__view__company {
    font-size: 15px;
    display: block;
    margin: 10px 0px 10px;
    color: #e84727;
}
.link__view__company:hover,
.link__view__company:active,
.link__view__company:focus{
    color: #bcbcbc;
}
.statistics-job {
    padding: 30px 25px 20px;
    margin-bottom: 20px;
}
.statistics-job p{
    margin-bottom: 10px;
    padding-left: 30px;
    position: relative;
    color: #666;
}
.statistics-job i{
    width: 20px;
    height: 12px;
    background: url(../img/statistics_i.png) no-repeat center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
.statistics-job p:nth-of-type(2) i {
    height: 14px;
    width: 14px;
    left: 3px;
    background: url(../img/statistics_p.png) no-repeat center;

}
.statistics-job b{
    float: right;
}

.step-job{
    padding-bottom: 30px;
}

.radius-line{
    margin: 20px auto;
    position: relative;
    width: 158px;
    height: 158px;
}
.radius-line:before,
.radius-line:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 106%;
    background: #b0b0b0;
    margin: auto;
    z-index: 1;
}
.radius-line:after{
    width: 106%;
    height: 1px;
    left: -3%;
}
.radius{
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.radius-1 {
    width: 10px;
    height: 10px;
    background-color: rgb(207, 22, 24);
    z-index: 2;
}
.radius-2 {
    width: 54px;
    height: 54px;
    background-color: rgb(236, 236, 236);
}
.radius-2.active{
    background-color: #ff9000;
}
.radius-3 {
    background-color: rgb(226, 226, 226);
    width: 90px;
    height: 90px;
}
.radius-3.active{
    background-color: #ffa71d;
}
.radius-4{
    background-color: rgb(217, 217, 217);
    width: 126px;
    height: 126px;
}
.radius-4.active{
    background-color: #ffbb51;
}

.radius-5{
    background-color: rgb(207, 207, 207);
    width: 158px;
    height: 158px;
    position: relative;
}
.radius-5.active{
    background-color: #ffdb79;
}


.status-list {
    width: 150px;
    margin: 0 auto 25px;
    counter-reset: heading;
}
.status-list li{
    color: #c5c5c5;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 1px;
    padding-left: 30px;
    position: relative;
}
.status-list li:before{
    counter-increment: heading;
    content: counter(heading);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}
.status-list li.active{
    color: #666;
}
.status-list li.no-active{
    color: #0597cd;
}
.status-list li.active:before{
    content: '';
    background: url(../img/status_ok.png) no-repeat;
    width: 16px;
    height: 16px;
    left: -4px;
    top: -3px;
}
/*
.alert {
    position: fixed;
    top: 50%;
    right: 7%;
    margin: 0 auto;
    width: 400px;
    padding: 20px;
    border: 1px solid rgb(241, 241, 241);
    border-radius: 5px;
    background-color: #fff;
    z-index: 100;
}*/

form input.error,
form select.error{
    border: 2px solid #f60c0f!important;
}

.form-reg input::-webkit-input-placeholder {color:#888;font-size: 13px;font-family: 'latomedium_italic';}
.form-reg input::-moz-placeholder          {color:#888;font-size: 13px;font-family: 'latomedium_italic';}/* Firefox 19+ */
.form-reg input:-moz-placeholder           {color:#888;font-size: 13px;font-family: 'latomedium_italic';}/* Firefox 18- */
.form-reg input:-ms-input-placeholder      {color:#888;font-size: 13px;font-family: 'latomedium_italic';}




/* header */

/* header news */
header:not(.main__header){
    background-color: #131313;
    min-height: 57px;
    padding-top: 5px;
}
.main__header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    padding-top: 5px;
    z-index: 200;
    background-color: #131313;
}

.main__menu{
    margin-top: 20px;
}
.main__menu > ul{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    text-align: right;
}
.main__menu > ul > li{
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    position: relative;
}
.main__menu > ul > li.lang{
    width: 34px;
}
.main__menu > ul > li.menu__mobile__visible{
    display: none;
}
.menu__info{
    padding-right: 10px;
}
.menu__info:hover ul{
    display: block;
}
.menu__info ul {
    position: absolute;
    bottom: -40px;
    display: none;
    background: rgba(0,0,0,0.9);
    padding: 5px;
    padding-bottom: 0px;
    z-index: 10;
    border-radius: 10px;
}
.menu__info:hover ul li{
    margin-bottom: 5px;
}
.main__menu a{
    color: #fff;
    font-size: 15px;
    font-family: 'latolight';
}
.main__menu a:hover{
    color: rgb(47, 135, 226);
    border-color: rgb(47, 135, 226);
}
.main__menu a.active{
    color: rgb(47, 135, 226);
}

.main__menu li.lang ul{
    position: relative;
    width: 34px;
    height: 25px;
    margin: 0 auto;
    z-index: 9;
}
.main__menu li.lang a{
    font-size: 12px;
}
.main__menu li.lang li{
    border-radius: 50%;
    background-color: #000;
    line-height: 25px;
    text-align: left;
    text-indent: 8px;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}
.main__menu li.lang li.active:after{
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 6px;
    width: 4px;
    height: 4px;
    margin: auto;
    border: 1px solid #fff;
    border-width: 0px 1px 1px 0px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.main__menu li.lang li:not(.active){
    display: none;
}
.main__menu li.lang:hover li:not(.active){
    display: block;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.main__menu li.lang:hover li:not(.active):nth-child(2) {
    border-radius: 0;
}

.main__menu li.lang:hover li.active{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.main__menu__login input{
    border: 1px solid #fff;
    background-color: rgb(255, 255, 255);
    width: 130px;
    height: 25px;
    border-radius: 14px;
    padding-left: 5px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;
}
.main__header__form{
    width: 350px;
}
.main__header__login{
    text-align: right!important;
    padding-right: 20px;
}
.main__menu__login{
    position: relative;
}
.main__menu__login button{
    width: 50px;
    height: 25px;
    background-color: rgb(47, 135, 226);
    border-radius: 16px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-family: 'latolight';
    display: inline-block;
    vertical-align: middle;
}

.main__header__form__reset{
    position: absolute;
    top: -23px;
}

.main__header__form__reset a {
    font-size: 12px;
    color: #a5a2a2;
    margin-right: 20px;
    margin-left: 10px;
    border-bottom: 1px solid #a5a2a2;
    padding-bottom: 2px;
}


.main__logo {
    margin-top: -1px;
}


.mobile__menu{
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #37383d;
    top: 0;
    left: 0;
    z-index: 1000;
    padding: 20px;
    text-align: right;
}
.mobile__menu a {
    font-size: 30px;
    border-bottom: 1px solid #9999991a;
    line-height: 48px;
    width: 100%;
    display: block;
}
.mobile__menu li{
    margin-bottom: 10px;
}

.login__other__pages{
    display: none!important;
}


/* end header */



/*
header .container{
    position: relative;
}
header.homepage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}
header nav{
    background: rgb(22,7,42);
    background: -moz-linear-gradient(top, rgba(32, 8, 64,1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(top, rgba(32, 8, 64,1) 0%,rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom, rgba(32, 8, 64,1) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#16072a', endColorstr='#000000',GradientType=0 );
    height: 90px;
}
.brand{
    height: 90px;
    position: relative;
}
.brand img{
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
}
header .menu {
    position: absolute;
    bottom: 14px;
    right: 0;
}
*/
.menu li{
    display: inline-block;
    padding-left: 25px;
    line-height: 16px;
    letter-spacing: 1px;
}
.menu a{
    color: #cfcfcf;
    font-size: 16px;
    font-family: 'latosemibold';
    padding-bottom: 10px;
    position: relative;
}
.menu a.active{
    color: #fff;
    padding-bottom: 5px;
    border-bottom: 5px solid #0597cd;
}
.menu a:not(.active):hover:before{
    border-bottom: 5px solid #0597cd;
}

.menu a:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    transition: .4s all;
    -webkit-transition: .4s width;
    -moz-transition: .4s width;
    -ms-transition: .4s width;
    -o-transition: .4s width;
}
.menu a:hover:before{
    width: 100%;
    transition: .4s all;
    -webkit-transition: .4s width;
    -moz-transition: .4s width;
    -ms-transition: .4s width;
    -o-transition: .4s width;
}

/*
header .login{
    margin-top: 7px;
    margin-left: 70px;
}
header .login > a:not(#notification) {
    color: #eeeeef;
    font-size: 14px;
    padding: 0 25px;
    float: left;
    font-family: 'latoregular';
    letter-spacing: 1px;
    cursor: pointer;
}
header .login > a:hover {
    color: #0597cd !important;
}
.login .log-In{
    border-right: 1px solid #eeeeef;
    padding-right: 25px;
    padding-left: 0;
}
.top-bar.right{
    position: relative;
    min-width: 250px;
}
.enter{
    border: 1px solid rgb(241, 241, 241);
    border-radius: 5px;
    background-color: #fff;
    width: 259px;
    height: 319px;
    position: absolute;
    top: 30px;
    left: 91px;
    z-index: 100;
    display: none;
}
.enter p{
    font-size: 14px;
    color: #0d0d0d;
    letter-spacing: 0.5px;
    padding: 18px 0px 5px;
}
.enter .h4-span{
    display: block;
    text-transform: uppercase;
    color: #0597cd;
    font-size: 16px;
    font-family: 'latobold';
    letter-spacing: 1px;
    text-align: center;
}
.enter a{
    display: block;
    text-align: center;
    font-size: 12px;
    color: #666;
    letter-spacing: 1px;

}
.enter a span{
    border-bottom: 1px dotted #eaeaea;
}
.enter form{
    padding: 30px 20px 0px;
}
.enter input{
    border: 1px solid rgb(214, 214, 214);
    border-radius: 5px;
    background-color: #fff;
    width: 100%;
    height: 32px;
    line-height: 32px;
    margin: 0 auto;
    display: block;
    padding-left: 10px;
}
.enter button{
    margin: 10px 0;
}

ul.language{
    border: 1px solid rgb(80, 80, 80);
    border-radius: 0px 0px 10px 10px;
    background-color: rgb(5, 151, 205);
    width: 56px;
    height: auto;
    float: right;
    text-align: center;
    position: relative;
    z-index: 10;
}
ul.language:hover li{
    display: block;
}
.language li.active{
    display: block;
}
.language li{
    display: none;
}
.language li.active a:after{
    content: '';
    position: absolute;
    top: -6px;
    bottom: 0;
    height: 6px;
    width: 6px;
    right: 8px;
    margin: auto;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.language a{
    color: #eeeeef;
    font-size: 12px;
    height: 25px;
    text-transform: uppercase;
    display: block;
    line-height: 25px;
    position: relative;
    cursor: pointer;
}


*/

/* footer */
footer{
    margin-top: 30px;
    min-height: 200px;
    background-color: #000;
    padding-top: 40px;
    background: -moz-linear-gradient(top, rgba(32, 8, 64,1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(top, rgba(32, 8, 64,1) 0%,rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom, rgba(32, 8, 64,1) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#16072a', endColorstr='#000000',GradientType=0 );
}
footer nav{
    margin-bottom: 24px;
}
.menu-footer li:first-child{
    padding-left: 0px;
}
.menu-footer li{
    border-right: 1px solid #cfcfcf;
    padding-right: 15px;
    padding-left: 15px;
}
.menu-footer li:last-child{
    border-right: none;
}
.title-footer-h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 16px;
    display: block;
}
.mb{
    margin-bottom: 24px;
}
ul.feedback *,ul.copyright *{
    color: #ddd;
    font-size: 14px;
    line-height: 18px;
}
ul.feedback a:hover {
    color: #0aa4dd;
}
ul.social li{
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #4e4e4e;
    background-color: #3b3b3b;
    width: 24px;
    height: 24px;
}
ul.social {
    margin-top: 10px;
    margin-bottom: 30px;
}
ul.social a{
    /*background-image: url(../img/social.png);*/
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #a1a1a1;
    font-size: 16px;
    line-height: 24px;
}
ul.social a:not(.fa) {
    font-family: 'latosemibold';
}
ul.social a:hover {
    border-radius: 100%;
    color: rgb(148, 177, 255);
    box-shadow: #3b3ba3 0px 0px 5px 2px;
}

ul.copyright a{
    color: #0aa4dd;
    text-transform: uppercase;
}

.upload {
    color: #57a7ca;
    font-size: 12px;
    font-family: 'Times-Italic';
    background: none;
}

/* select date*/
.date-time select{
    width: auto!important;
    float: none!important;
    height: 20px!important;
    padding: 0!important;
    margin: auto;
    padding: 0px 3px!important;
    line-height: 100%;
    border-radius: 4px!important;
    display: inline-block;
}
.date-time{
    min-width: 110px;
    text-align: center;
}

.sms-send-answer {
    height: 35px;
    line-height: 35px;
    display: inline-block;
    font-size: 15px;
    color: #37383d;
    font-family: 'latosemibold';
}


.w12th{
    width: 12%;
    text-align: center!important;
    padding: 0!important;
}
.w15th{
    width: 15%;
    text-align: center!important;
    padding: 0!important;
}
/*    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;*/
/*margin: auto;*/
/* width: 200px;*/
/*    z-index: 10;
    background: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(53, 54, 59, 0.33);
    height: 39px;
}
*/
/*.close-date{
    position: absolute;
    top: 6px;
    right: 0;
    height: 15px;
    width: 15px;
    cursor: pointer;
    background-color: transparent;
}

.close-date:before,.close-date:after{
    content: '';
    position: absolute;
    top: 6px;
    right: 0;
    height: 2px;
    width: 100%;
    background-color: #ec694f;
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.close-date:after{
    bottom: 0;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
}*/

.hidInfoCerf .close{
    position: absolute;
    top: 10px;
    right: 10px;
    height: 15px;
    width: 15px;
    background-color: transparent;
}

.hidInfoCerf .close:before,
.hidInfoCerf .close:after{
    content: '';
    position: absolute;
    top: 10px;
    right: 1px;
    height: 2px;
    width: 100%;
    background-color: #35363b;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.hidInfoCerf .close:after{
    top: inherit;
    bottom: 12px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}




.dropzone .dz-preview .dz-progress { opacity: 1; -webkit-transition: all 0.2s linear; -moz-transition: all 0.2s linear; -ms-transition: all 0.2s linear; -o-transition: all 0.2s linear; transition: all 0.2s linear; }
.dropzone .dz-preview .dz-progress { opacity: 0; -webkit-transition: opacity 0.4s ease-in; -moz-transition: opacity 0.4s ease-in; -ms-transition: opacity 0.4s ease-in; -o-transition: opacity 0.4s ease-in; transition: opacity 0.4s ease-in; }
.dropzone .dz-preview:not(.dz-processing) .dz-progress { -webkit-animation: pulse 6s ease infinite; -moz-animation: pulse 6s ease infinite; -ms-animation: pulse 6s ease infinite; -o-animation: pulse 6s ease infinite; animation: pulse 6s ease infinite; }
.dropzone .dz-preview.dz-file-old.dz-processing .dz-progress{ opacity: 1; z-index: 1000; pointer-events: none; position: absolute; height: 16px; left: 50%; top: 50%; margin-top: -8px; width: 80px; margin-left: -40px; background: rgba(255, 255, 255, 0.9); -webkit-transform: scale(1); border-radius: 8px; overflow: hidden; }
.dropzone .dz-preview.dz-file-old.dz-processing .dz-upload { background: #333; background: linear-gradient(to bottom, #666, #444); position: absolute; top: 0; left: 0; bottom: 0; width: 0; -webkit-transition: width 300ms ease-in-out; -moz-transition: width 300ms ease-in-out; -ms-transition: width 300ms ease-in-out; -o-transition: width 300ms ease-in-out; transition: width 300ms ease-in-out; }


.dropzone .dz-preview{
    height: 100px;
    width: 100px;
}

.uploadLogo .dz-preview{
    float: none;
}

.dropzone .error .dz-progress{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../img/img_error.png) rgba(0, 0, 0, 0.4) no-repeat;
    z-index: 1000;
    opacity: 1;
}

.hidLabel .button.upload{
    display: none !important;
}


#notification{
    width: 20px;
    height: 24px;
    display: inline-block;
    background: url(../img/bell.png) no-repeat center;
    display: none;
}

.name-logout{
    position: relative;
    padding-left: 16px;
    padding-right: 20px;
    vertical-align: top;
    display: inline-block;
}
.name-logout:before{
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    background: url(../img/login_i.png) no-repeat;
    width: 14px;
    height: 14px;
}
.name-logout a{
    color: #eeeeef;
    font-size: 14px;
    font-family: 'latoregular';
    letter-spacing: 1px;
    padding-right: 8px;
}
.name-logout #name-logout  li{
    display: inline-block;
}
/*#name-logout {*/
/*display: none;*/
/*position: absolute;*/
/*top: 14px;*/
/*left: 0;*/
/*width: 100%;*/
/*padding: 5px;*/
/*border: 1px solid #35363b;*/
/*border-radius: 5px;*/
/*background: #f0f0f0;*/
/*z-index: 10;*/
/*}*/
/*#name-logout a{*/
/*color: #35363b;*/
/*display: block;*/
/*margin-bottom: 9px;*/
/*line-height: 100%;*/
/*}*/
#name-logout li:last-child a {
    border-left: 1px solid #eeeeef;
    padding-left: 35px;
    padding-right: 0;
    position: relative;
}

#name-logout li:last-child a:before{
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    width: 20px;
    height: 14px;
    background: url(../img/logout_i.png) no-repeat;

}
.info-prof{
    position: absolute;
    top: 0;
    right: 30px;
    font-size: 12px;
    letter-spacing: 1px;
}
.info-prof .id-profile{
    display: block;
    text-align: right;
    color: #666;
    margin-bottom: 10px;
}
.info-prof span.update-info{
    font-family: 'latoitalic';
    color: #c5c5c5;
}

.tabPrint th{
    height: 33px;
    line-height: 33px;
}

.captcha_status .g-recaptcha > div {
    margin: 20px auto;
    text-align: center;
}

.summary-modal{
    position: fixed;
    top: 10%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    display: none;
}
.body-modal-summary{
    border: 1px solid rgb(214, 214, 214);
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    max-width: 752px;
    width: 95%;
    height: 570px;
    margin: 0 auto;
    padding: 30px 25px;
    position: relative;
}
.summary-text *{
    letter-spacing: 1px;
    color: #666;
    font-family: 'latoregular';
    font-size: 14px;
}
.summary-text {
    height: 300px;
    overflow: auto;
    margin: 20px 0;
    padding: 10px 16px;
    color: #777;
    font-family: 'latoregular';
    font-size: 14px;
    background-color: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.body-modal-summary h3{
    color: #0597cd;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
}
.argee {
    position: relative;
    padding-left: 26px;
    margin: 15px 0 15px 40px;
    color: #0597cd;
    font-family: 'latosemibold';
    font-size: 13px;
    display: block;
}
.btn-x:disabled{
    background-color: #d3d1d1;
}

.close-modal{
    position: absolute;
    top: -14px;
    right: -14px;
    height: 27px;
    width: 27px;
    background: url(../img/close-modal.png) no-repeat;
    cursor: pointer;
}

.img-cropp-text h3{
    font-size: 16px;
    color: #0597cd;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.img-cropp-text  p {
    color: #666;
    font-size: 14px;
    font-family: 'latoregular';
    letter-spacing: 1px;
    line-height: 19px;
}

.flag-select select{
    display: none;
}
.table td[data-date="date"]{
    padding: 0!important;
    cursor: pointer;
}

.table td[data-date="date"].active{
    background: none;
}
.table td[data-date="date"] .date-time{
    display: none;
}
.table td[data-date="date"].active .date-time{
    display: block;
}
.ul-select{
    width: 100%;
    white-space: nowrap;
    height: 17px;
    -webkit-transition:  0.5s height ;
    -moz-transition:  0.5s height ;
    -o-transition:  0.5s height ;
    transition:  0.5s height ;
    padding: 0px 15px 0px 5px;
}
.flag-select,
[data-select="select"]{
    padding: 0!important;
}

.sort-by-vac ul {
    left: inherit;
    right: 0;
}
.sort-by-vac ul{
    border: 1px solid rgb(214, 214, 214);
    border-width: 0 1px 1px 1px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
}
.sort-by-vac ul a{
    padding: 5px 10px;
    white-space: nowrap;
    display: block;
}
.sort-by-vac ul {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
}
.sort-by-vac ul a:hover{
    background-color: #ecf3f8;
}

.ul-select{
    width: 60px;
    max-width: 130px;
    overflow: hidden;
}
.table[data-name="service"] td[data-date="date"].active .date-time{
    min-width: 68px;
}
[data-parent="form-reg"] .ul-select{
    max-width: 100px;
}
.ul-select.active{
    margin: auto!important;
    max-width: 100%!important;
    width: 100% !important;
    height: 200px;
    overflow: auto;
    z-index: 1000;
    -webkit-transition:   0.5s height ;
    -moz-transition:  0.5s height ;
    -o-transition:  0.5s height ;
    transition:  0.5s height ;
}

.ul-select li span{
    display: inline-block;
    color: #666;
    font-size: 13px;
    font-family: 'latosemibold';
    vertical-align: middle;
    margin-right: 4px;
}
.ul-select li span.nameFlag{
    display: none;
}
.ul-select.active li:hover span{
    color: #000;
}
.ul-select li{
    display: none;
    cursor: pointer;
}
.ul-select.active li{
    display: block;
}
.ul-select.active li span.nameFlag{
    display: inline-block;
}
.ul-select li.active{
    display: block;
}
/*.ul-select:not(.active):hover{
    width: auto;
    max-width: 100%;
    -webkit-transition:  0.5s all ;
    -moz-transition:  0.5s all ;
    -o-transition:  0.5s all ;
    transition:  0.5s all ;
}*/
.ul-select:not(.active) li.active:hover span.nameFlag {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #fffffb;
    z-index: 100;
    background: rgb(53, 54, 59);
    height: 19px;
    padding: 0 10px;
    line-height: 20px;
    border-radius: 5px;
}
.ul-select.active li.active span{
    color: #0597cd;
}

.prev-page{
    color: #666!important;
    font-size: 14px;
    font-family: 'latosemibold';
    display: block;
    margin-bottom: 8px;
}

.closeFlag{
    position: absolute;
    top: 5px;
    right: 20px;
    height: 27px;
    width: 27px;
    background: url(../img/close-modal.png) no-repeat;
    cursor: pointer;
}

.dz-file-preview img{
    background: url(../img/pdf.png) no-repeat;
    background-size: contain;
}


.change-password {
    padding-top: 40px;
}

.change-password p {
    font-size: 15px;
}
.change-password form{
    border: 1px solid rgb(235, 235, 235);
    border-radius: 5px;
    max-width: 490px;
    width: 100%;
    background-color: #fafafa;
    padding: 40px 30px;
    margin: 30px auto;
}

.change-password input{
    height: 33px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    padding-left: 15px;
    font-family: 'latomedium_italic';
    font-size: 13px;
    width: 100%;
    margin: 10px 0px 20px;
}
.change-password form p{
    display: inline-block;
}

.mobile__menu .close{
    background: url(../img/close.svg) no-repeat;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    z-index: 1000;
}

.flag {
    background-image: url(../img/flag.png);
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
}

.flag-ad {
    width: 20px;
    height: 20px;
    background-position: -1px -1px;
}

.flag-ae {
    width: 20px;
    height: 20px;
    background-position: -23px -1px;
}

.flag-af {
    width: 20px;
    height: 20px;
    background-position: -45px -1px;
}

.flag-ag {
    width: 20px;
    height: 20px;
    background-position: -67px -1px;
}

.flag-ai {
    width: 20px;
    height: 20px;
    background-position: -89px -1px;
}

.flag-al {
    width: 20px;
    height: 20px;
    background-position: -111px -1px;
}

.flag-am {
    width: 20px;
    height: 20px;
    background-position: -133px -1px;
}

.flag-an {
    width: 20px;
    height: 20px;
    background-position: -155px -1px;
}

.flag-ao {
    width: 20px;
    height: 20px;
    background-position: -177px -1px;
}

.flag-aq {
    width: 20px;
    height: 20px;
    background-position: -199px -1px;
}

.flag-ar {
    width: 20px;
    height: 20px;
    background-position: -221px -1px;
}

.flag-as {
    width: 20px;
    height: 20px;
    background-position: -243px -1px;
}

.flag-at {
    width: 20px;
    height: 20px;
    background-position: -265px -1px;
}

.flag-au {
    width: 20px;
    height: 20px;
    background-position: -287px -1px;
}

.flag-aw {
    width: 20px;
    height: 20px;
    background-position: -1px -23px;
}

.flag-az {
    width: 20px;
    height: 20px;
    background-position: -23px -23px;
}

.flag-ba {
    width: 20px;
    height: 20px;
    background-position: -45px -23px;
}

.flag-bb {
    width: 20px;
    height: 20px;
    background-position: -67px -23px;
}

.flag-bd {
    width: 20px;
    height: 20px;
    background-position: -89px -23px;
}

.flag-be {
    width: 20px;
    height: 20px;
    background-position: -111px -23px;
}

.flag-bf {
    width: 20px;
    height: 20px;
    background-position: -133px -23px;
}

.flag-bg {
    width: 20px;
    height: 20px;
    background-position: -155px -23px;
}

.flag-bh {
    width: 20px;
    height: 20px;
    background-position: -177px -23px;
}

.flag-bi {
    width: 20px;
    height: 20px;
    background-position: -199px -23px;
}

.flag-bj {
    width: 20px;
    height: 20px;
    background-position: -221px -23px;
}

.flag-bm {
    width: 20px;
    height: 20px;
    background-position: -243px -23px;
}

.flag-bn {
    width: 20px;
    height: 20px;
    background-position: -265px -23px;
}

.flag-bo {
    width: 20px;
    height: 20px;
    background-position: -287px -23px;
}

.flag-br {
    width: 20px;
    height: 20px;
    background-position: -1px -45px;
}

.flag-bs {
    width: 20px;
    height: 20px;
    background-position: -23px -45px;
}

.flag-bt {
    width: 20px;
    height: 20px;
    background-position: -45px -45px;
}

.flag-bw {
    width: 20px;
    height: 20px;
    background-position: -67px -45px;
}

.flag-by {
    width: 20px;
    height: 20px;
    background-position: -89px -45px;
}

.flag-bz {
    width: 20px;
    height: 20px;
    background-position: -111px -45px;
}

.flag-ca {
    width: 20px;
    height: 20px;
    background-position: -133px -45px;
}

.flag-cd {
    width: 20px;
    height: 20px;
    background-position: -155px -45px;
}

.flag-cf {
    width: 20px;
    height: 20px;
    background-position: -177px -45px;
}

.flag-cg {
    width: 20px;
    height: 20px;
    background-position: -199px -45px;
}

.flag-ch {
    width: 20px;
    height: 20px;
    background-position: -221px -45px;
}

.flag-ci {
    width: 20px;
    height: 20px;
    background-position: -243px -45px;
}

.flag-ck {
    width: 20px;
    height: 20px;
    background-position: -265px -45px;
}

.flag-cl {
    width: 20px;
    height: 20px;
    background-position: -287px -45px;
}

.flag-cm {
    width: 20px;
    height: 20px;
    background-position: -1px -67px;
}

.flag-cn {
    width: 20px;
    height: 20px;
    background-position: -23px -67px;
}

.flag-co {
    width: 20px;
    height: 20px;
    background-position: -45px -67px;
}

.flag-cr {
    width: 20px;
    height: 20px;
    background-position: -67px -67px;
}

.flag-cu {
    width: 20px;
    height: 20px;
    background-position: -89px -67px;
}

.flag-cv {
    width: 20px;
    height: 20px;
    background-position: -111px -67px;
}

.flag-cy {
    width: 20px;
    height: 20px;
    background-position: -133px -67px;
}

.flag-cz {
    width: 20px;
    height: 20px;
    background-position: -155px -67px;
}

.flag-de {
    width: 20px;
    height: 20px;
    background-position: -177px -67px;
}

.flag-dj {
    width: 20px;
    height: 20px;
    background-position: -199px -67px;
}

.flag-dk {
    width: 20px;
    height: 20px;
    background-position: -221px -67px;
}

.flag-dm {
    width: 20px;
    height: 20px;
    background-position: -243px -67px;
}

.flag-do {
    width: 20px;
    height: 20px;
    background-position: -265px -67px;
}

.flag-dz {
    width: 20px;
    height: 20px;
    background-position: -287px -67px;
}

.flag-ec {
    width: 20px;
    height: 20px;
    background-position: -1px -89px;
}

.flag-ee {
    width: 20px;
    height: 20px;
    background-position: -23px -89px;
}

.flag-eg {
    width: 20px;
    height: 20px;
    background-position: -45px -89px;
}

.flag-eh {
    width: 20px;
    height: 20px;
    background-position: -67px -89px;
}

.flag-er {
    width: 20px;
    height: 20px;
    background-position: -89px -89px;
}

.flag-es {
    width: 20px;
    height: 20px;
    background-position: -111px -89px;
}

.flag-et {
    width: 20px;
    height: 20px;
    background-position: -133px -89px;
}

.flag-fi {
    width: 20px;
    height: 20px;
    background-position: -155px -89px;
}

.flag-fj {
    width: 20px;
    height: 20px;
    background-position: -177px -89px;
}

.flag-fm {
    width: 20px;
    height: 20px;
    background-position: -199px -89px;
}

.flag-fo {
    width: 20px;
    height: 20px;
    background-position: -221px -89px;
}

.flag-fr {
    width: 20px;
    height: 20px;
    background-position: -243px -89px;
}

.flag-ga {
    width: 20px;
    height: 20px;
    background-position: -265px -89px;
}

.flag-gb {
    width: 20px;
    height: 20px;
    background-position: -287px -89px;
}

.flag-gd {
    width: 20px;
    height: 20px;
    background-position: -1px -111px;
}

.flag-ge {
    width: 20px;
    height: 20px;
    background-position: -23px -111px;
}

.flag-gg {
    width: 20px;
    height: 20px;
    background-position: -45px -111px;
}

.flag-gh {
    width: 20px;
    height: 20px;
    background-position: -67px -111px;
}

.flag-gi {
    width: 20px;
    height: 20px;
    background-position: -89px -111px;
}

.flag-gl {
    width: 20px;
    height: 20px;
    background-position: -111px -111px;
}

.flag-gm {
    width: 20px;
    height: 20px;
    background-position: -133px -111px;
}

.flag-gn {
    width: 20px;
    height: 20px;
    background-position: -155px -111px;
}

.flag-gp {
    width: 20px;
    height: 20px;
    background-position: -177px -111px;
}

.flag-gq {
    width: 20px;
    height: 20px;
    background-position: -199px -111px;
}

.flag-gr {
    width: 20px;
    height: 20px;
    background-position: -221px -111px;
}

.flag-gt {
    width: 20px;
    height: 20px;
    background-position: -243px -111px;
}

.flag-gu {
    width: 20px;
    height: 20px;
    background-position: -265px -111px;
}

.flag-gw {
    width: 20px;
    height: 20px;
    background-position: -287px -111px;
}

.flag-gy {
    width: 20px;
    height: 20px;
    background-position: -1px -133px;
}

.flag-hk {
    width: 20px;
    height: 20px;
    background-position: -23px -133px;
}

.flag-hn {
    width: 20px;
    height: 20px;
    background-position: -45px -133px;
}

.flag-hr {
    width: 20px;
    height: 20px;
    background-position: -67px -133px;
}

.flag-ht {
    width: 20px;
    height: 20px;
    background-position: -89px -133px;
}

.flag-hu {
    width: 20px;
    height: 20px;
    background-position: -111px -133px;
}

.flag-id {
    width: 20px;
    height: 20px;
    background-position: -133px -133px;
}

.flag-ie {
    width: 20px;
    height: 20px;
    background-position: -155px -133px;
}

.flag-il {
    width: 20px;
    height: 20px;
    background-position: -177px -133px;
}

.flag-im {
    width: 20px;
    height: 20px;
    background-position: -199px -133px;
}

.flag-in {
    width: 20px;
    height: 20px;
    background-position: -221px -133px;
}

.flag-iq {
    width: 20px;
    height: 20px;
    background-position: -243px -133px;
}

.flag-ir {
    width: 20px;
    height: 20px;
    background-position: -265px -133px;
}

.flag-is {
    width: 20px;
    height: 20px;
    background-position: -287px -133px;
}

.flag-it {
    width: 20px;
    height: 20px;
    background-position: -1px -155px;
}

.flag-je {
    width: 20px;
    height: 20px;
    background-position: -23px -155px;
}

.flag-jm {
    width: 20px;
    height: 20px;
    background-position: -45px -155px;
}

.flag-jo {
    width: 20px;
    height: 20px;
    background-position: -67px -155px;
}

.flag-jp {
    width: 20px;
    height: 20px;
    background-position: -89px -155px;
}

.flag-ke {
    width: 20px;
    height: 20px;
    background-position: -111px -155px;
}

.flag-kg {
    width: 20px;
    height: 20px;
    background-position: -133px -155px;
}

.flag-kh {
    width: 20px;
    height: 20px;
    background-position: -155px -155px;
}

.flag-ki {
    width: 20px;
    height: 20px;
    background-position: -177px -155px;
}

.flag-km {
    width: 20px;
    height: 20px;
    background-position: -199px -155px;
}

.flag-kn {
    width: 20px;
    height: 20px;
    background-position: -221px -155px;
}

.flag-kp {
    width: 20px;
    height: 20px;
    background-position: -243px -155px;
}

.flag-kr {
    width: 20px;
    height: 20px;
    background-position: -265px -155px;
}

.flag-kw {
    width: 20px;
    height: 20px;
    background-position: -287px -155px;
}

.flag-ky {
    width: 20px;
    height: 20px;
    background-position: -1px -177px;
}

.flag-kz {
    width: 20px;
    height: 20px;
    background-position: -23px -177px;
}

.flag-la {
    width: 20px;
    height: 20px;
    background-position: -45px -177px;
}

.flag-lb {
    width: 20px;
    height: 20px;
    background-position: -67px -177px;
}

.flag-lc {
    width: 20px;
    height: 20px;
    background-position: -89px -177px;
}

.flag-li {
    width: 20px;
    height: 20px;
    background-position: -111px -177px;
}

.flag-lk {
    width: 20px;
    height: 20px;
    background-position: -133px -177px;
}

.flag-lr {
    width: 20px;
    height: 20px;
    background-position: -155px -177px;
}

.flag-ls {
    width: 20px;
    height: 20px;
    background-position: -177px -177px;
}

.flag-lt {
    width: 20px;
    height: 20px;
    background-position: -199px -177px;
}

.flag-lu {
    width: 20px;
    height: 20px;
    background-position: -221px -177px;
}

.flag-lv {
    width: 20px;
    height: 20px;
    background-position: -243px -177px;
}

.flag-ly {
    width: 20px;
    height: 20px;
    background-position: -265px -177px;
}

.flag-ma {
    width: 20px;
    height: 20px;
    background-position: -287px -177px;
}

.flag-mc {
    width: 20px;
    height: 20px;
    background-position: -1px -199px;
}

.flag-md {
    width: 20px;
    height: 20px;
    background-position: -23px -199px;
}

.flag-me {
    width: 20px;
    height: 20px;
    background-position: -45px -199px;
}

.flag-mg {
    width: 20px;
    height: 20px;
    background-position: -67px -199px;
}

.flag-mh {
    width: 20px;
    height: 20px;
    background-position: -89px -199px;
}

.flag-mk {
    width: 20px;
    height: 20px;
    background-position: -111px -199px;
}

.flag-ml {
    width: 20px;
    height: 20px;
    background-position: -133px -199px;
}

.flag-mm {
    width: 20px;
    height: 20px;
    background-position: -155px -199px;
}

.flag-mn {
    width: 20px;
    height: 20px;
    background-position: -177px -199px;
}

.flag-mo {
    width: 20px;
    height: 20px;
    background-position: -199px -199px;
}

.flag-mq {
    width: 20px;
    height: 20px;
    background-position: -221px -199px;
}

.flag-mr {
    width: 20px;
    height: 20px;
    background-position: -243px -199px;
}

.flag-ms {
    width: 20px;
    height: 20px;
    background-position: -265px -199px;
}

.flag-mt {
    width: 20px;
    height: 20px;
    background-position: -287px -199px;
}

.flag-mu {
    width: 20px;
    height: 20px;
    background-position: -1px -221px;
}

.flag-mv {
    width: 20px;
    height: 20px;
    background-position: -23px -221px;
}

.flag-mw {
    width: 20px;
    height: 20px;
    background-position: -45px -221px;
}

.flag-mx {
    width: 20px;
    height: 20px;
    background-position: -67px -221px;
}

.flag-my {
    width: 20px;
    height: 20px;
    background-position: -89px -221px;
}

.flag-mz {
    width: 20px;
    height: 20px;
    background-position: -111px -221px;
}

.flag-na {
    width: 20px;
    height: 20px;
    background-position: -133px -221px;
}

.flag-nc {
    width: 20px;
    height: 20px;
    background-position: -155px -221px;
}

.flag-ne {
    width: 20px;
    height: 20px;
    background-position: -177px -221px;
}

.flag-ng {
    width: 20px;
    height: 20px;
    background-position: -199px -221px;
}

.flag-ni {
    width: 20px;
    height: 20px;
    background-position: -221px -221px;
}

.flag-nl {
    width: 20px;
    height: 20px;
    background-position: -243px -221px;
}

.flag-no {
    width: 20px;
    height: 20px;
    background-position: -265px -221px;
}

.flag-np {
    width: 20px;
    height: 20px;
    background-position: -287px -221px;
}

.flag-nr {
    width: 20px;
    height: 20px;
    background-position: -1px -243px;
}

.flag-nz {
    width: 20px;
    height: 20px;
    background-position: -23px -243px;
}

.flag-om {
    width: 20px;
    height: 20px;
    background-position: -45px -243px;
}

.flag-pa {
    width: 20px;
    height: 20px;
    background-position: -67px -243px;
}

.flag-pe {
    width: 20px;
    height: 20px;
    background-position: -89px -243px;
}

.flag-pf {
    width: 20px;
    height: 20px;
    background-position: -111px -243px;
}

.flag-pg {
    width: 20px;
    height: 20px;
    background-position: -133px -243px;
}

.flag-ph {
    width: 20px;
    height: 20px;
    background-position: -155px -243px;
}

.flag-pk {
    width: 20px;
    height: 20px;
    background-position: -177px -243px;
}

.flag-pl {
    width: 20px;
    height: 20px;
    background-position: -199px -243px;
}

.flag-pr {
    width: 20px;
    height: 20px;
    background-position: -221px -243px;
}

.flag-ps {
    width: 20px;
    height: 20px;
    background-position: -243px -243px;
}

.flag-pt {
    width: 20px;
    height: 20px;
    background-position: -265px -243px;
}

.flag-pw {
    width: 20px;
    height: 20px;
    background-position: -287px -243px;
}

.flag-py {
    width: 20px;
    height: 20px;
    background-position: -1px -265px;
}

.flag-qa {
    width: 20px;
    height: 20px;
    background-position: -23px -265px;
}

.flag-re {
    width: 20px;
    height: 20px;
    background-position: -45px -265px;
}

.flag-ro {
    width: 20px;
    height: 20px;
    background-position: -67px -265px;
}

.flag-rs {
    width: 20px;
    height: 20px;
    background-position: -89px -265px;
}

.flag-ru {
    width: 20px;
    height: 20px;
    background-position: -111px -265px;
}

.flag-rw {
    width: 20px;
    height: 20px;
    background-position: -133px -265px;
}

.flag-sa {
    width: 20px;
    height: 20px;
    background-position: -155px -265px;
}

.flag-sb {
    width: 20px;
    height: 20px;
    background-position: -177px -265px;
}

.flag-sc {
    width: 20px;
    height: 20px;
    background-position: -199px -265px;
}

.flag-sd {
    width: 20px;
    height: 20px;
    background-position: -221px -265px;
}

.flag-se {
    width: 20px;
    height: 20px;
    background-position: -243px -265px;
}

.flag-sg {
    width: 20px;
    height: 20px;
    background-position: -265px -265px;
}

.flag-si {
    width: 20px;
    height: 20px;
    background-position: -287px -265px;
}

.flag-sk {
    width: 20px;
    height: 20px;
    background-position: -1px -287px;
}

.flag-sl {
    width: 20px;
    height: 20px;
    background-position: -23px -287px;
}

.flag-sm {
    width: 20px;
    height: 20px;
    background-position: -45px -287px;
}

.flag-sn {
    width: 20px;
    height: 20px;
    background-position: -67px -287px;
}

.flag-so {
    width: 20px;
    height: 20px;
    background-position: -89px -287px;
}

.flag-sr {
    width: 20px;
    height: 20px;
    background-position: -111px -287px;
}

.flag-st {
    width: 20px;
    height: 20px;
    background-position: -133px -287px;
}

.flag-sv {
    width: 20px;
    height: 20px;
    background-position: -155px -287px;
}

.flag-sy {
    width: 20px;
    height: 20px;
    background-position: -177px -287px;
}

.flag-sz {
    width: 20px;
    height: 20px;
    background-position: -199px -287px;
}

.flag-tc {
    width: 20px;
    height: 20px;
    background-position: -221px -287px;
}

.flag-td {
    width: 20px;
    height: 20px;
    background-position: -243px -287px;
}

.flag-tg {
    width: 20px;
    height: 20px;
    background-position: -265px -287px;
}

.flag-th {
    width: 20px;
    height: 20px;
    background-position: -287px -287px;
}

.flag-tj {
    width: 20px;
    height: 20px;
    background-position: -309px -1px;
}

.flag-tl {
    width: 20px;
    height: 20px;
    background-position: -309px -23px;
}

.flag-tm {
    width: 20px;
    height: 20px;
    background-position: -309px -45px;
}

.flag-tn {
    width: 20px;
    height: 20px;
    background-position: -309px -67px;
}

.flag-to {
    width: 20px;
    height: 20px;
    background-position: -309px -89px;
}

.flag-tr {
    width: 20px;
    height: 20px;
    background-position: -309px -111px;
}

.flag-tt {
    width: 20px;
    height: 20px;
    background-position: -309px -133px;
}

.flag-tv {
    width: 20px;
    height: 20px;
    background-position: -309px -155px;
}

.flag-tw {
    width: 20px;
    height: 20px;
    background-position: -309px -177px;
}

.flag-tz {
    width: 20px;
    height: 20px;
    background-position: -309px -199px;
}

.flag-ua {
    width: 20px;
    height: 20px;
    background-position: -309px -221px;
}

.flag-ug {
    width: 20px;
    height: 20px;
    background-position: -309px -243px;
}

.flag-us {
    width: 20px;
    height: 20px;
    background-position: -309px -265px;
}

.flag-uy {
    width: 20px;
    height: 20px;
    background-position: -309px -287px;
}

.flag-uz {
    width: 20px;
    height: 20px;
    background-position: -1px -309px;
}

.flag-va {
    width: 20px;
    height: 20px;
    background-position: -23px -309px;
}

.flag-vc {
    width: 20px;
    height: 20px;
    background-position: -45px -309px;
}

.flag-ve {
    width: 20px;
    height: 20px;
    background-position: -67px -309px;
}

.flag-vg {
    width: 20px;
    height: 20px;
    background-position: -89px -309px;
}

.flag-vi {
    width: 20px;
    height: 20px;
    background-position: -111px -309px;
}

.flag-vn {
    width: 20px;
    height: 20px;
    background-position: -133px -309px;
}

.flag-vu {
    width: 20px;
    height: 20px;
    background-position: -155px -309px;
}

.flag-ws {
    width: 20px;
    height: 20px;
    background-position: -177px -309px;
}

.flag-ye {
    width: 20px;
    height: 20px;
    background-position: -199px -309px;
}

.flag-za {
    width: 20px;
    height: 20px;
    background-position: -221px -309px;
}

.flag-zm {
    width: 20px;
    height: 20px;
    background-position: -243px -309px;
}

.flag-zw {
    width: 20px;
    height: 20px;
    background-position: -265px -309px;
}


.year__plan {
    position: fixed;
    height: 250px;
    max-width: 500px;
    width: 96%;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    z-index: 99999;

}

.share-show li:last-child a{
    background: url(../img/link.png) center no-repeat;
}

.alert-danger.main-page {
    position: absolute;
    top: 50px;
}


.profile-modal {
    position: fixed;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    display: none;
}

.body-modal-profile {
    border: 1px solid rgb(214, 214, 214);
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    width: 752px;
    height: 570px;
    margin: 0 auto;
    padding: 30px 25px;
    position: relative;
}


.logo-fleet {
    display: block;
    width: 100%;
    max-width: 300px;
    height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    margin-top: 20px;
}
.logo-fleet img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    height: 100%;
}

[data-modlog].enter{
    position: fixed;
    left: 0;
    right: 0;
    top: 13%;
    margin: 0 auto;
    max-width: 420px;
    width: 95%;
    height: auto;
}

[data-modlog].enter button{
    margin: 15px auto;
}
[data-modlog].enter  form {
    padding: 35px 30px 20px;
}

[data-modlog].enter .regist{
    color: #7bbad5;
    font-size: 14px;
    font-family: 'latomedium';
    color: rgb(5, 151, 205);
    margin-top: 14px;
    text-align: center;
}
[data-modlog].enter .close-modal{
    margin: 0;
}

#clipboard {
    position: absolute;
    top: 0;
    left: -999999px;
}

.main__list  span {
    color: #0d0d0d;
    font-size: 15px!important;
    font-family: 'latobold';
    padding: 3px 0;
}
.main__list > li > span {
    font-size: 16px!important;
    color: rgb(5, 151, 205);
    padding: 0;
}
.main__list a {
    color: #666;
    cursor: pointer;
    line-height: 30px;
    white-space: nowrap;
}
select.error + .data-rank-tree{
    border: 2px solid #f60c0f !important;
}
.data-rank-tree.active .main__list a{
    padding-left: 15px;
    display: block;
    font-size: 14px;
}
[data-rank-tree]:not(th){
    display: none!important;
}
.data-rank-tree.active .main__list li[data-parentp] > ul{
    margin-left: 15px;
    line-height: 30px;
}


.data-rank-tree.active li,
.data-rank-tree.active span{
    display: block!important;
}
.data-rank-tree.active > span{
    display: none;
}
.create-vacancy .data-rank-tree.active{
    position: absolute;
    top: 0;
    z-index: 10;
}
.create-vacancy .data-rank-tree{
    overflow: hidden;
}
.data-rank-tree.active a{
    line-height: 18px;
}
.data-rank-tree.active li[data-vall]:hover {
    background-color: #ecf3f8;
}
.data-rank-tree.active li[data-vall].active{
    background-color: #ecf3f8;
}
/*
.data-rank-tree li:not(.active):not([data-parentmain]),
.data-rank-tree span{
    display: none;
}

.data-rank-tree > span{
    display: block;
    line-height: 30px;
}*/

.data-rank-tree.active{
    padding: 10px 15px;
    overflow-y: scroll;
    -webkit-transition:  0.5s height ;
    -moz-transition:  0.5s height ;
    -o-transition:  0.5s height ;
    transition:  0.5s height ;
}
.data-rank-tree {
    height: auto !important;
    max-height: 300px;
    border: 1px solid rgba(214, 214, 214,0.5)!important;
    padding: 0px 12px;
    font-size: 13px;
    color: #666;
    line-height: 30px;
    letter-spacing: 0.5px;
    font-family: 'latoregular';
    background: url(../img/select_arrow.png) #fff 96% center no-repeat;
    -webkit-transition:  0.5s height ;
    -moz-transition:  0.5s height ;
    -o-transition:  0.5s height ;
    transition:  0.5s height ;
    display: none;
    position: absolute;
    top: 37px;
    z-index: 1;
    left: -1px;
}

.table .data-rank-tree.active{
    width: auto;
    overflow-y: auto;
}
table .data-rank-tree{
    height: auto;
    padding: 0 10px;
    max-width: inherit;
    width: auto;
    float: none;
    background: #fff;
    display: none;
}

.data-rank-tree.active{
    padding: 10px 15px;
    background-image: none;
    z-index: 10;
}

.table .main__list a {
    display: block;
}
[data-selectall].error +  .option__name{
    border: 1px solid red;
}

.option__name{
    display: block;
    padding: 11px 5px;
    width: 100%;
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    cursor: pointer;
    color: #888;
    font-size: 12px;
}

a[data-login]{
    display: block;
}

.still-yellow td,
.still-yellow input,
.still-yellow select,
.still-yellow .select2-container--default .select2-selection--single{
    background-color: #fff1bf!important;
}

.still-red td,
.still-red input,
.still-red select,
.still-red .select2-container--default .select2-selection--single{
    background-color: #f7bdb1!important;
}

.still-green td,
.still-green input,
.still-green select,
.still-green .select2-container--default .select2-selection--single{
    background-color: #bdddca!important;
}

.img-cert b{
    height: 141px;
    width: 100px;
    border-radius: 5px;
    border: 1px solid rgb(230, 230, 230);
    overflow: hidden;
    display: block;
    float: left;
    margin-bottom: 10px;
    cursor: pointer;
}

.imgPdf {
    background: url(../img/pdf.png) no-repeat;
    background-size: contain;
    width: 100px!important;
    height: 100px!important;
    display: block;
    border: none !important;
}

.img-cert b img{
    max-width: 100%;
    height: auto;
    min-height: 100%;
    border: none !important;
}
.righttop {
    float: right;
}

@media (max-width: 1190px){
    .text-info{
        padding-right: 0!important;
    }
    .menu li{
        border: none;
        padding: 0px 10px;
    }

    footer .no-padding,
    second-screen .no-padding{
        padding: 0 10px!important;
    }
    .row[data-parent]{
        margin: 0!important;
        margin-top: 30px!important;
        position: relative!important;
    }
    .border .btn{
        margin-top: 0px!important;
    }
    [data-parent="form-reg"] p{
        width: 30%!important;
        max-width: 30%!important;
        padding: 0!important;
    }
    .summary[data-parent="form-reg"]  p{
        width: 90%!important;
    }
    [data-parent="form-reg"] input, [data-parent="form-reg"] select:not(.date):not(.month):not(.year):not(.w154){
        max-width: 70%!important;
        width: 70%!important;
    }
    .general-info .w50 {
        max-width: 100%!important;
        margin-bottom: 10px!important;

    }
    .general-info .w50:last-child{
        margin-bottom: 0!important;
    }
    [data-parent="form-reg"] .borderR5{
        padding: 20px 0!important;
        overflow-x: hidden;
    }
    .form-group.w50{
        max-width: 100%;
        width: 100%!important;
    }
    [data-parent="form-reg"] input.w154, [data-parent="form-reg"] select.w154{
        max-width: 17.1% !important;
        width: 100% !important;
        padding: 0 5px !important;
        margin-left: 0 !important;
        display: inline-block;
        float: none !important;
        vertical-align: top;
    }
    [data-parent="form-reg"] table input, [data-parent="form-reg"] table select:not(.date):not(.month):not(.year),[data-parent="form-reg"] table p{
        width: 100%!important;
        max-width: 100%!important;
        margin: 0;
    }
    .biometrics table p, .biometrics table input, .biometrics table select{
        margin-bottom: 10px!important;
    }
    .search-vacancies {
        padding-left: 10px!important;
        padding-right: 10px!important;
    }
    .vacancies-tab .vacancies, .s-tab .vacancies{
        padding: 20px!important;
    }
    .vacancies .info-company,.vacancies .price{
        position: static;
        top: 0;
        right: 0
    }
    .vacancies .info-company{
        float: right;
        margin-bottom: 15px;
    }
    .vacancies .price{
        width: auto;
        margin: 0 auto 10px;
    }
    .vacancies .price .price-price,
    .vacancies .price .btn{
        display: inline-block;
        margin: 10px 10px;
        padding: 0;
    }
    .vacancies .price:before,
    .vacancies .price:after{
        content: '';
        display: table;
        clear: both;
    }
    .over-scroll {
        overflow: auto;
    }

    .order-by {
        height: auto!important;
        margin: 10px auto 0!important;
        display: inline-block;
    }
    .main-vacancies .vacancies .item{
        padding: 20px!important;
        height: 150px;
    }
    .main-vacancies  .vacancies .title-company,
    .main-vacancies .vacancies .info-company,
    .main-vacancies .block-price,
    .main-vacancies .vacancies .price{
        width: 40%!important;
        margin: 0!important;
        float: left;
    }
    .vacancies ul.info,
    .main-vacancies .amend{
        display: block;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto;
        height: 15px;
    }
    .main-vacancies .amend{
        width: 120px;
        margin: auto;
        top: 0;
    }
    .main-vacancies .price-price {
        text-align: left;
    }
    .main-vacancies .vacancies .price .btn {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 10;
    }
    /*ul.language li{*/
    /*display: inline-block !important;*/
    /*}*/
    /*ul.language li.active{*/
    /*background-color: #57a7ca;*/
    /*padding: 0 9px;*/
    /*}*/
    /*ul.language li.active a:after{*/
    /*display: none;*/
    /*}*/
    /*ul.language{*/
    /*width: 75px;*/
    /*}*/

}

@media (max-width: 1024px){
    .vacancies-crew .item .look a{
        position: relative;
        margin: 15px 0 !important;
    }
    .modal-window{
        top: 15% !important;
    }
    .title-h1 {
        font-size: 30px;
    }
    .title-h2{
        font-size: 20px;
    }
    .accordion > div {
        margin-bottom: 25px;
    }
    .icon-d{
        padding: 0 10px;
        height: 160px;
        margin-bottom: 25px;
    }
    .section-icons .row{
        margin: 0;
    }
    .section-icons .no-padding{
        padding-left: 10px!important;
        padding-right: 10px!important;
    }
    .top-img .btn-w{
        float: none;
        margin: 0 auto;
    }
    .seo-text-simple-page .btn{
        margin: 0 auto 25px;
        float: none;
    }
    .btn-slider .btn{
        float: none;
        margin: 25px auto;
    }
    .form-reg-emp{
        margin-right: 10px!important;
        margin-left: 10px!important;
    }
    .general-info div.form,
    .personal-info div.form,
    .biometrics div.form,
    .training-certificates div.form,
    .medical-certificates div.form,
    .summary div.form{
        padding: 0 10px!important;
    }
    .enter{
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    header .login{
        margin-left: 0;
    }
}
@media (max-width: 1023px){

    .view-applicants {
        margin-top: 10px;
    }
    .header-slider video{
        display: none;
    }

    .body-modal-summary{
        padding: 20px 15px;
        transform: translateY(-35px);
    }
    .argee{
        margin: 15px 0 15px 16px;
    }
    .newwin{
        transform: translateY(-36px) translateX(280px);
    }
    .slider-group-box{
        display: block !important;
    }
    .slider-group-box button.btn-slider{
        margin: 0 auto;
        width: 100px !important;
    }
    .hidden-sm {
        display: none!important;
    }
    .col-1-4{
        display: block;
        float: left;
        width: 14.2857143%!important;
    }
    .navigator-form-reg .tabs-s{
        color: transparent;
        overflow: hidden;
    }
    .navigator-form-reg .tabs-s b{
        color: #fff;
        height: 15px;
        text-align: center;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
    .biometrics table input,
    .biometrics table select:not(.date):not(.month):not(.year){
        min-width: auto!important;
        max-width: 150px!important;
    }

    .navigation .tabs-s > span {
        text-indent: -999999px;
        display: block;
        height: 100%;
        width: 100%;
    }
    .navigation .icon-t span:before{
        left: 0!important;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .personal-detalis .borderR5, .sea-service .borderR5, .documents .borderR5,
    .create-vacancy .borderR5{
        padding: 15px!important;
    }
    .profile .borderR5 {
        padding: 20px!important;
    }
    .create-vacancy select{
        margin-bottom: 10px!important;
    }
}

@media (max-width: 992px){
    span.xclose {
        float: right;
        bottom: 51px !important;
        right: 14px !important;
    }
    .seamanSearch {
        width: calc(100% - 37px) !important;
        max-width: none !important;
        margin-top: 30px !important;
        margin-left: 0px !important;
        bottom: 20px !important;
    }
    .registraton li{

    }
    .seo-text-bg{
        display: none;
    }
    .seo-text-simple-page {
        min-height: auto!important;
        height: auto;
        margin-top: 10px!important;
    }
    .text-simple-page{
        padding-top: 20px!important;
    }
    .mx388{
        margin: 0 auto;
        float: none!important;
    }
    .contacts .contact-form{
        padding: 20px!important;
    }
    footer .contact {
        float: none!important;
        width: 200px;
        margin: 0 auto;
    }
    .copyright {
        text-align: center;
        margin: 20px;
    }
    footer .menu-footer{
        -webkit-column-width: auto;
        -moz-column-width: auto;
        column-width: auto;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 20px;
        -moz-column-gap: 20px;
        column-gap: 20px;
        height: auto;
    }
    .menu li{
        width: 100%;
        margin-bottom: 15px;
        padding: 0 14px !important;
    }
    .menu:not(.menu-footer) li a{
        font-size: 20px;
        line-height: 20px;
    }
    .menu a.active, .menu a:hover {
        padding-bottom: 5px !important;
    }
    .text-simple-page h4 {
        margin-left: 50px;
    }
    .vacancies .item{
        padding-left: 20px;
        padding-right: 0px !important;
    }
    .vacancies .price {
        right: 20px!important;
    }
    .info.no-paddingR{
        padding: 0!important
    }
    .info.no-paddingR > div{
        margin-top: 20px !important;
        width: 49%;
    }
    .personal-detalis p, .personal-detalis input, .personal-detalis textarea, .personal-detalis select{
        padding-left: 10px!important;
    }
    .edit-prof .dd[colspan='2']{
        width: 36%!important;
    }
    .edit-prof .dd{
        width: 100%;
    }
    /*.s-tab .no-paddingR{*/
    /*padding: 0!important;*/
    /*}*/
    .vacancies-crew .item:before{
        display: none;
    }
    .vacancies-crew .item{
        margin-left: 0 !important;
    }

    .fleetMd {
        clear: both;
        margin-bottom: 20px;
    }
    .sms_send_form .input_sms,
    .sms_send_form .captcha_sms{
        width: 100% !important;
    }
    .sms_send_form input.w154,
    .sms_send_form .btn-w50{
        max-width: 36%!important;
    }
}

@media (max-width: 767px){
    .logo-fleet {
        width: 100%;
        max-width: unset !important;
    }
    .logo-fleet img{
        width: 100%;
    }


    div.breadcrumb{
        height: auto;
        padding: 10px 0;
    }
    .breadcrumb{
        min-height: 100%;
    }
    .breadcrumb li{
        line-height: 140%;
    }
    .filter__mobile{
        display: none;
    }
    .filter__title{
        cursor: pointer;
        position: relative;
    }
    .filter__title:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #000;
        border-left: 1px solid #000;
        -webkit-transform: rotate(-45deg)!important;
        -ms-transform: rotate(-45deg)!important;
        -o-transform: rotate(-45deg)!important;
        transform: rotate(-45deg)!important;
    }
    .slider-button{
        top: 0!important;
        bottom: 0!important;
        margin: auto!important;
    }
    .next-img.slider-button{
        left: inherit!important;
        right: 10px!important;
    }
    .prev-img.slider-button{
        right: inherit!important;
        left: 10px!important;
    }
    .prev-img.slider-button:before,
    .next-img.slider-button:before {
        -webkit-transform: rotate(-45deg)!important;
        -ms-transform: rotate(-45deg)!important;
        -o-transform: rotate(-45deg)!important;
        transform: rotate(-45deg)!important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        margin: auto !important;
        bottom: 0 !important;
    }
    .img-slider-crewing{
        display: none !important;
    }
    .img-slider-list li{
        display: inline-block;
        height: inherit !important;
        max-height: 300px;
        margin-bottom: 0 !important;
        text-align: center;
    }
    .img-slider-list{
        height: 300px!important;
    }
    .img-slider-list ul{
        height: 100% !important;
        letter-spacing: -10px;
    }
    .img-slider-list img{
        height: 100% !important;
        width: auto !important;
    }

    .s-tab .no-paddingR{
        padding: 0 !important;
    }

    .form-fleet label{
        display: inline-block !important;
        font-size: 15px!important;
        margin: 5px 0;
    }
    .form-fleet .form-group{
        height: auto !important;
        line-height: 100% !important;
    }
    .form-fleet select,
    .form-fleet input:not([type="checkbox"]){
        max-width: 100% !important;
        display: block;
    }
    .table th{
        min-width: 100px;
    }
    .table th[data-name="subtype"] {
        min-width: 140px;
    }
    .sort-by-vac .container-sort{
        display: inline-block !important;
        width: 100% !important;
    }
    .result .sort-by-vac .container-sort{
        width: auto !important;
    }
    .sort-by-vac .list__sort dt.click,
    .sort-by-vac .list__sort dt{
        width: 110px !important;
        text-align: center;
        display: inline-block !important;
    }
    .result .line-b {
        margin: 0 20px;
    }
    .registraton .no-paddingR,
    .registraton .no-paddingL{
        padding: 0 10px !important;
        margin-bottom: 20px;
    }
    .contacts .contacts-info,
    .applied .container{
        margin-top: 20px !important;
        padding: 20px !important;
    }
    .title-footer-h3,
    .social{
        text-align: center;
    }
    .faq .item span{
        height: auto!important;
        line-height: 20px!important;
        padding: 10px 20px;
    }
    [data-parent="form-reg"] .no-paddingR{
        padding: 0!important;
    }
    [data-parent="form-reg"] input.w154, [data-parent="form-reg"] select.w154{
        max-width: 49% !important;
        width: 49% !important;
        float: left !important;
        margin: 0 0 10px 0!important;
        display: block
    }
    [data-parent="form-reg"] input.w310{
        margin: 0 0 10px 0!important;
    }
    [data-parent="form-reg"] input.w154:nth-of-type(1),[data-parent="form-reg"] input.w154:nth-of-type(3){
        float: right!important;
    }
    .biometrics tr {
        display: block;
    }
    .biometrics td {
        width: 50%;
        display: block;
        float: left;
    }
    .biometrics table input, .biometrics table select{
        max-width: 100%!important;
        margin-bottom: 10px;
    }
    .brand img {
        width: 180px;
        top: 28px;
    }
    .vacancies-tab .no-paddingR, .s-tab .vacancies .no-paddingR{
        padding: 0!important;
    }
    .vacancies-tab .vacancies, .s-tab .vacancies{
        padding: 0px 5px!important;
    }
    .vacancies .item{
        padding: 60px 10px 0!important;
        margin-left: 0!important;
        max-width: 400px;
        margin: 20px 10px!important;
    }
    .vacancies .item:not(.no-before):before{
        top: 10px;
        bottom: inherit;
        left: 20px!important;
        background-color: transparent!important;
    }
    .vacancies .item.hot-smart:after{
        display: none;
    }
    .vacancies .item.hot:after{
        left: 20px!important;
        bottom: inherit;
        background-color: transparent!important;
    }
    .vacancies .title-company, .vacancies .info-company{
        float: none;
        margin: 0 auto 20px;
        width: auto!important;
    }
    .vacancies-tab .result{
        margin-top: 20px;
    }
    .mrb30{
        border-radius: 5px 5px 0 0;
        border: 1px solid #e6e6e6;
        overflow: hidden;
        border-top: none;
        margin-bottom: 30px!important;
    }
    .personal-detalis .borderRTLR5:not(thead):not(table){
        border: none!important;
        margin: 0!important;
    }
    .title-table{
        border-right: none!important;
    }
    .downloadFile .left,
    .downloadFile .right{
        float: none!important;
        margin: 10px auto;
    }
    .downloadFile .col-sm-6{
        padding: 0!important
    }
    .edit-prof td:not([data-date='date']){
        display: block;
    }
    .edit-prof td .btn.right{
        float: none;
        margin: 10px auto;
    }
    .edit-prof .dt,
    .edit-prof .dd{
        display: block;
        margin-bottom: 10px;
        width: 100% !important;
    }
    .edit-prof tr{
        display: block;
        margin-bottom: 15px;
        height: auto !important;
    }
    .table-edit,
    .table-edit tbody {
        display: block;
    }
    .edit-prof .table-edit select:not(.date):not(.month):not(.year){
        width: 100% !important;
        max-width: 100%;
    }
    .edit-prof .table-edit textarea{
        margin-top: 0 !important;
        height: 100%;
    }
    .edit-prof .date-time{
        float: none;
        text-align: left!important;
    }
    .edit-prof .dd[colspan='2']{
        width: 100%!important;
    }
    .vacancy-temp .no-paddingR{
        padding: 0!important;
        margin-top: 20px;
    }
    .vacancy-temp .PLPR{
        padding: 0px 20px!important;
    }
    .main-vacancies .borderR5{
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
    .vacancy-temp .infoPerson dt,
    .vacancy-temp .infoPerson dd{
        width: 100%;
        margin-bottom: 10px;
        line-height: 100%;
        display: block;
    }
    .job .h2-span{
        margin: 20px 0;
    }
    .addTR {
        width: 100%;
        display: block;
        margin-bottom: 15px;
    }
    .change-plan{
        margin-bottom: 20px;
    }
    .profile dd.text-info{
        padding-right: 0px!important;
    }
    .info-of-fleet table{
        width: 100%!important;
    }
    .create-vacancy .search-vacancies .form-group{
        max-width: 100%!important;
    }
    .create-vacancy .search-vacancies{
        margin-bottom: 20px!important;
        max-width: 320px;
        margin: 0 auto;
    }
    .create-vacancy select {
        max-width: 100%!important;
    }
    .row.min-requery{
        margin: 20px 0px;
    }
    .packet{
        float: none;
        margin: 10px auto!important;
    }
    .link-button .left,
    .link-button .right{
        float: none;
        margin: 10px auto 0;
    }
    .share-show{
        position: relative!important;
        top: 0!important;
        right: 0!important;
        float: right!important;
        margin-bottom: 13px!important;
    }
    .main-vacancies  .vacancies .title-company,
    .main-vacancies .vacancies .info-company,
    .main-vacancies .block-price,
    .main-vacancies .vacancies .price,
    .vacancies ul.info,
    .main-vacancies .amend,
    .main-vacancies .vacancies .price .btn{
        display: block!important;
        position: relative!important;
        top: 0!important;
        right: 0!important;
        width: 100%!important;
        float: none!important;
        margin: 10px auto!important;
    }
    .vacancies ul.info{
        width: 120px!important
    }
    .main-vacancies .vacancies .item{
        height: 350px;
        padding: 10px!important;
        padding-top: 40px!important;
    }

    .main-vacancies .title-company,
    .main-vacancies .price-price{
        text-align: center;
    }
    .vacancies .item.smart:not(.hot):after{
        left: 15px;
        background: url(../img/smart.png) center no-repeat;
        height: 21px;
        width: 18px;
        top: 25px;
        bottom: inherit;
    }
    .container-sort {
        display: block!important;
        margin-bottom: 10px !important;
        border-right: none!important;
        padding: 0!important;
        margin-left: 0 !important;
    }
    .container-sort select{
        max-width: 100% !important;
    }
    .filter-vac input[type="search"] {
        margin-top: 20px !important;
        max-width: 100% !important;
    }
    [data-tabs="navS"] .vacancies .item:not(.no-before):before{
        display: none;
    }
    .vacancies-crew .info-status,
    .vacancies-crew .info-candidate,
    .vacancies-crew .img-candidate{
        float: none!important;
        margin: 0 auto;
    }
    .vacancies-crew .item .look a,
    .vacancies-crew .item .look button.btn,
    .vacancies-crew .look.price{
        position: relative;
        right: 0!important;
    }
    .img-candidate .round{
        margin: 0 auto 10px;
    }
    .sms_send_form #send_sms:not(:empty), .sms_send_form #confirm_code:not(:empty){
        position: relative;
    }
    .vacancies-crew .item{
        padding-top: 20px!important;
    }

}
@media (max-width: 660px){
    .search-vacancies {
        padding-top: 11px !important;
        padding-left: 10px!important;
        padding-right: 10px!important;
        max-width: none;
        padding-bottom: 0px;
    }
    .search-vacancies .h3-span {
        margin-bottom: 10px;
    }
    .button__view__seaman .btn {
        text-align: center;
        margin-bottom: 15px;
        width: auto;
        padding: 0 25px;
        font-size: 15px;
    }
    .righttop {
        float: right;
    }
    .body-plan{
        height: 320px;
        overflow: auto;
    }
    .plan-modal .item {
        width: 100% !important;
        margin-bottom: 20px;
    }
    .form-reg-seam input, .form-reg-emp input, .form-reg-emp select:not(.date):not(.month):not(.year),
    .form-reg-seam p, .form-reg-emp p{
        float: none!important;
        display: block;
        max-width: 100%!important;
    }
    .form-reg-seam .form-group{
        max-width: 320px;
        margin: 0 auto;
    }
    .contact-form .title-h2{
        margin-bottom: 20px!important;
    }
    .tabs-s > span:before{
        display: none!important;
    }
    .navigation .tabs-s > span:before{
        display: block!important;
    }

    .tabs-s{
        letter-spacing: 1px;
        font-size: 15px!important;
    }
    [data-parent="form-reg"] p,
    [data-parent="form-reg"] input,
    [data-parent="form-reg"] select:not(.date):not(.month):not(.year):not(.w154){
        max-width: 100%!important;
        width: 100%!important;
        margin: 0;
        margin-bottom: 10px;
        padding: 0 10px!important;
    }
    [data-parent="form-reg"] p{
        margin-bottom: 10px!important;
        line-height: 100%!important;
        padding: 0!important;
    }
    .general-info div.form,
    .personal-info div.form,
    .biometrics div.form,
    .training-certificates div.form,
    .medical-certificates div.form,
    .summary div.form{
        padding: 0px 20px!important;
    }
    [data-parent="form-reg"] a.btn,
    [data-parent="form-reg"] .btn.next{
        width: 40%!important;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .navigator-form-reg .tabs-s:before{
        display: none;
    }
    .info.no-paddingR > div {
        width: 100%;
        margin-bottom: 0 !important;
    }
    .info-prof {
        position: relative;
        right: 0;
        margin: 20px 0;
        height: auto;
    }
    .info-prof > span{
        display: inline-block!important;
        vertical-align: top;
    }
    .info-prof span.update-info{
        float: right;
    }
    #vessel_settings .logo-fleet {
        display: none;
    }
    .sms_send_form input.w154{
        max-width: 100% !important;
        width: 100% !important;
    }
    .packet-window{
        padding-left: 15px!important;
        padding-right: 15px!important;
    }
    .packet-window .btn{
        max-width: 130px!important;
    }
}

@media (max-width: 374px){
    .contact-form input, .contact-form textarea{
        display: block;
        width: 100%!important;
        max-width: 100%!important;
    }
    [data-parent="form-reg"] input.w154, [data-parent="form-reg"] select.w154{
        max-width: 100% !important;
        width: 100% !important;
    }
}


.cookie {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    text-align: center;
    font-family: 'latoregular';
    letter-spacing: 0px;
    background-color: rgb(55, 56, 61);
    z-index: 100;
    color: #fff;
    padding: 10px 30px;
    margin-right: 20px;
    -webkit-animation-name: cookian;  /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 2s;  /* Safari 4.0 - 8.0 */
    -webkit-animation-delay: 2s; /* Safari 4.0 - 8.0 */
    -webkit-animation-fill-mode: both; /* Safari 4.0 - 8.0 */
    animation-name: cookian;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-fill-mode: both;
}

@-webkit-keyframes cookian {
    from {bottom: -200px; background-color: black;}
    to {bottom: 0px; background-color: rgb(55, 56, 61);}
}

@keyframes cookian {
    from {bottom: -200px; background-color: black;}
    to {bottom: 0px; background-color: rgb(55, 56, 61);}
}

.cookie .btn{
    display: inline-block;
    height: auto;
    width: auto;
    padding: 8px 10px;
    color: #fff;
}
.select2-container .select2-selection--single,
.select2-container--default .select2-search--dropdown .select2-search__field,
.select2-dropdown{
    border: 1px solid #e6e6e6!important;
}
.select2-container--default .select2-selection--single{
    border: none !important;
}
.select2-selection__rendered{
    color: #888 !important;
}

[lang="ru"] .text-simple-page .btn{
    max-width: 260px;
}

[data-delete]{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

.uploadImages.dropzone.img-cert{
    min-height: 200px;
    border: 3px dashed #e6e6e6;
    display: block;
    background: url(../img/plus.png) center no-repeat;
    padding: 0px 10px;
}
.uploadImages.dropzone.img-cert:hover{
    cursor: pointer;
    background-color: rgba(230, 230, 230, 0.3);
}

.uploadImages.dropzone.img-cert .dz-default.dz-message{
    display: block;
    position: relative;
    top: 0;
    left: 0;
    font-size: 20px;
    color: #21608d;
    font-family: 'latomedium_italic';
    font-size: 15px;
    color: #888;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 20px;
}

/*NEW*/

.IN-widget{
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    position: relative;
    top: 4px;
}

.IN-logo{
    height: 19px !important;
}

a.btn.btn-w.fb::before {
    content: url(../img/fblogin.png);
    display:inline-block;
    right: 40px;
    float: left;
}

a.btn.btn-w.in::before {
    content: url(../img/inlogin.png);
    display:inline-block;
    right: 40px;
    float: left;
}

a.btn.btn-w.gp::before {
    content: url(../img/gplogin.png);
    display:inline-block;
    right: 40px;
    float: left;
}

a.btn.btn-w.gp,
a.btn.btn-w.in,
a.btn.btn-w.fb{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 15px;

}

.paraOR {
    text-align: center;
    font-size: 1.5em;
    color: rgba(90, 90, 90, 0.64);
    padding-bottom: 20px;
    font-weight: bolder;
}
/*NEW*/



/*NEW 04.05.17 18:45*/

.phonelink:focus {
    color: #5674a7 !important;
}

.phonelink:hover {
    color: #a17ef1 !important;

}

.rating {
    display: block;
    width: 128px;
    margin: auto;
    margin-bottom: 30px;
}

#doubt {
    animation: sel 2s linear 2s infinite alternate;
}

.rating>img {
    opacity: 0.1;
    transition: all ease-in 0.3s;
    cursor: pointer;
}

.rating>img:hover {
    opacity: 1.0;
    position: relative;

}

.rating>img[data-selected='true']{
    opacity: 1.0;
}

@keyframes sel {
    0%  {transform: rotate(0deg);}
    25% {transform: rotate(45deg);}
    50%  {transform: rotate(0deg);}
    100% {transform: rotate(-45deg);}
}

@media (min-width: 660px){
    .profile_name {
        color: #f5f7f8 !important;
        background-color: #446b87;
        height: 40px;
        padding: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .id-profile {

        color: #f6f6f6!important;
        margin-bottom: 10px;
        margin-top: 3px;
    }

}
/*NEW*/


.notetimestamp {
    position: relative;
    line-height: 30px;
    left: 6px;
    color: grey;
    font-family: Helvetica;
}
.noteManager {
    display: inline-block;
    line-height: 30px;
    position: relative;
    margin-left: 90px;
    color: grey;
}
.employer_notes {
    display: block;
    width: 100%;
    min-height: 100px;
    border: rgba(128, 128, 128, 0.25) 1px solid;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px;
    padding-top: 0px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.04);
    color: #6b92cc;
    font-family: Helvetica;
}
.employer_notes>ul {
    z-index: 100;
    float: right;
    position: relative;
    bottom: 5px;
    list-style-type: none;
    margin-top: 10px;
}

.employer_notes>ul>li {
    float: right;
    margin-left: 16px;
    opacity: 0.30;
    width: 20px;
    height: 20px;
    transition: opacity ease-in 0.3s;
    cursor: pointer;
}

.disabled{
    opacity: 0.1;
}


.employer_notes>ul>li:hover{
    opacity: 1.0;
}
.note-edit{
    background: url(/build/img/notesprite.png) no-repeat 0px 0px;
}
.note-save{
    background: url(/build/img/notesprite.png) no-repeat -21px 0px;
}
.note-del{
    background: url(/build/img/notesprite.png) no-repeat -44px 0px;
}

.employer_notes textarea{
    resize: vertical;
    width: 100%;
    border: none;
    background-color: #fff;
    border-top: solid rgba(66, 134, 182, 0.4) 1px;
    padding: 5px;
}
.employer_notes textarea:not(:disabled){
    min-height: 100px;
    border: 1px solid rgba(66, 134, 182, 0.47);
    border-radius: 3px;
}
.employer_notes textarea:disabled{
    resize: none;
    height: auto!important;
}
.employer_notes > ul > li.disabled{
    display: none;
}


/* table mobile */

.table__cpic {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    max-width: 150px;
}
.flag_div {
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.table__th__mob{
    display: none;
}

.table__mobile .updateCerf:nth-child(2n){
    background: rgba(240, 240, 240, 0.83);
}

@media (max-width: 1023px){
    .table__mobile thead {
        display: none;
    }
    .table__mobile tr,
    .table__mobile td{
        display: block;
        padding: 0;
    }
    .table__mobile td{
        height: 100%;
        min-height: auto;
    }
    .table__mobile td:not(:last-child){
        border-bottom: none;
    }
    .table__mobile td:last-child{
        height: 40px;
    }
    .table__mobile tr{
        margin-bottom: 10px;
    }
    .table__th__mob{
        display: inline-block;
        vertical-align: middle;
        color: #446b87;
        background-color: aliceblue;
        font-family: 'latobold';
        font-size: 12px;
        width: 50%;
        padding: 8px 0px;
        padding-left: 5px;
        word-break: break-word;
        border-right: 1px solid #e6e6e6;
    }
    tr.updateCerf div:nth-child(2) {
        font-size: 12px;
    }
    .table__th__mob + div{
        display: inline-block !important;
        width: 48% !important;
        vertical-align: middle;
    }
    .table__th__mob + div p{
        padding-left: 0 !important;
    }
    .served_fleets {
        display: none !important;
    }
}



/* news style media */

@media(max-width: 1199px){
    header .container{
        width: 100%;
    }

}
@media(max-width: 991px){
    .viber-bot-container {
        display: block;
        height: 100px !important;
        padding: 0 25px 20px !important;
        transform: translateY(-10px);
    }

    .viber-label{
        float: right;
        margin-right: 10px;
    }
    header:not(.main__header){
        min-height: auto;
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .main__menu{
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .main__logo img{
        width: auto;
    }
    .menu__mobile{
        display: none!important;
    }
    .mobile__menu.menu__show{
        display: block;
    }
    .main__menu > ul > li.menu__mobile__visible{
        display: block!important;
        width: 30px;
        height: 30px;
        float: right;
    }
    .scroll__window{
        float: none!important;
    }
    .main__header__form{
        max-width: 500px;
        width: 100%;
        padding-left: 30px;
    }
    .main__menu__login input{
        width: 40%;
    }
    .window__body{
        background-repeat: repeat-y!important;
    }
}
@media(max-width: 767px){
    .id-job {
        margin-left: 20px!important;
        position: relative;
        transform: translateY(-10px);
    }
    .logo_desktop{
        display: none !important;
    }
    .logo_mobile{
        display: block !important;
    }
    .login__other__pages{
        display: block!important;
        position: absolute!important;
        top: 0;
        top: -40px;
        right: 120px;
        z-index: 10;
    }
    .login__other__pages a{
        height: 30px;
        line-height: 30px;
        width: auto;
        padding: 0px 10px;
        margin-left: 5px;
    }
    .main__menu > ul > li.menu__mobile__visible,
    .main__menu > ul > li.lang{
        position: absolute;
        top: -40px;
        right: 20px;
        z-index: 10;
    }
    .main__menu > ul > li.lang{
        right: 72px; 
        top: -37px;
    }
    .main__menu > ul > li.menu__mobile__visible img{
        width: 100%;
    }

    .main__header__form{
        padding-left: 0;
        display: block!important;
        margin: 0 auto;
    }
    .main__menu__login input{
        width: 100%;
        max-width: 35%;
    }
    .main__menu__login{
        display: none;
    }
    .main__menu{
        margin: 0;
    }
    .window__mobile__text{
        text-transform: uppercase;
        text-align: center;
        font-family: 'latoitalic';
    }
    .main__header__login{
        display: block!important;
        margin: 12px auto;
        position: absolute!important;
        top: -43px;
        right: 104px;
        padding-right: 0;
    }
    .main__header__login .name-logout{
        padding-right: 0;
    }
    .main__header__login .log-Out{
        display: none;
    }
    .employer_notes>ul>li {
        float: right;
        margin-left: 10px;
        opacity: 0.30;
        width: 30px;
        height: 30px;
        transition: opacity ease-in 0.3s;
        cursor: pointer;
    }
    .employer_notes>ul {
        z-index: 100;
        float: right;
        position: relative;
        list-style-type: none;
        margin-top: 10px;
    }
    .noteManager {
        display: inline-block;
        line-height: 30px;
        position: relative;
        margin-left: 6px;
        color: grey;
    }
    .note-del {
        background: url(/build/img/notesprite.png) no-repeat -60px 0px;
        background-size: 90px;
        background-position-x: -60px;
    }
    .note-edit {
        background: url(/build/img/notesprite.png) no-repeat 0px 0px;
        background-size: 90px;
    }
    .note-save {
        background: url(/build/img/notesprite.png) no-repeat -30px 0px;
        background-size: 90px;
    }
    .served_fleets{
        display: none;
    }

}

span.pclose {
    display:  block;
    color: #ff0000;
    font-size: 72px;
    font-weight: bold;
    right: 10px;
    top: 10px;
    cursor:  pointer;
    position:  absolute;
}
span.pclose:hover {
    text-shadow: 0px 1px 20px #ff7272;
    color: #ff3558;
}

.ui-datepicker {
    width: 17em;
    padding: .2em .2em 0;
    display: none;
}
.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 6px;
    width: 1.8em;
    height: 1.8em;
    cursor: pointer;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
    color: #57a7ca !important;
}
.ui-datepicker .ui-datepicker-prev {
    left: 2px;
}
.ui-datepicker .ui-datepicker-next {
    right: 14px;
}
.ui-datepicker .ui-datepicker-prev-hover {
    left: 1px;
}
.ui-datepicker .ui-datepicker-next-hover {
    right: 13px;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -8px;
}
.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    width: 45%;
}
.ui-datepicker table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0 0 .4em;
}
.ui-datepicker th {
    padding: .7em .3em;
    text-align: center;
    font-weight: bold;
    border: 0;
}
.ui-datepicker td {
    border: 0;
    padding: 1px;
}
.ui-datepicker td span,
.ui-datepicker td a {
    display: block;
    padding: .2em;
    text-align: right;
    text-decoration: none;
    padding-right: 0.5em;
}
.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: .7em 0 0 0;
    padding: 0 .2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: .5em .2em .4em;
    cursor: pointer;
    padding: .2em .6em .3em .6em;
    width: auto;
    overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
}

/*#ui-datepicker-div {*/
    /*background-color: #f6f9fb;*/
    /*border: 1px solid #eaeaea;*/
    /*box-shadow: 0 0 20px 0px #00000021;*/
/*}*/

#ui-datepicker-div {
    background: linear-gradient(white,#f0f8ff);
    border: 1px solid #e1d6d6;
    box-shadow: 7px 7px 16px 0px #00000021;
    padding: 4px 10px;
}

select.ui-datepicker-month, select.ui-datepicker-year {
    height: 25px;
}
a.ui-state-default.ui-state-active {
    background-color: #57a7ca;
    color: white;
    border-radius: 8px;
}

/*a.ui-state-default {*/
    /*padding-left: 0px!important;*/
    /*padding-right: 8px!important;*/
/*}*/
a.ui-state-default:hover{
    background-color: #4e799b;
    color: white;
    border-radius: 8px;
}
.ui-datepicker-today {
    background-color: #b0daff;
    border-radius: 8px;
}
.ui-datepicker-next {
    right: 14px;
}

select.ui-datepicker-year {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
select.ui-datepicker-month {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.select2-container{
    width: 100%!important;
}

input#caller[type="checkbox"] + label span,
input#refer[type="checkbox"] + label span{
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-left: 61px;
}
input#caller[type="checkbox"],
input#refer[type="checkbox"]{
    display: none;
}
input#caller[type="checkbox"] + label span:before,
input#caller[type="checkbox"] + label span:after,
input#refer[type="checkbox"] + label span:before,
input#refer[type="checkbox"] + label span:after
{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
}

input#caller[type="checkbox"] + label span:before,
input#refer[type="checkbox"] + label span:before
{
    width: 48px;
    height: 25px;
    margin: auto;
    border-radius: 30px;
    border: 1px solid rgb(226, 226, 226);
    background-color: #f0f0f0;
}

input#caller[type="checkbox"] + label span:after,
input#refer[type="checkbox"] + label span:after
{
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: #fff;
    margin: auto;
    left: 2px;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    transition: 0.3s all;
}
input#caller[type="checkbox"]:checked + label span:after,
input#refer[type="checkbox"]:checked + label span:after
{
    background: #4286b6;
    left: 25px;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    transition: 0.3s all;
}
input#caller[type="checkbox"]:checked + label span:before,
input#refer[type="checkbox"]:checked + label span:before
{
    background: #b4cddf;
    border: 1px solid rgba(33, 96, 141, 0.18);
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    transition: 0.3s all;
}
.caller-group {
    position: relative;
    right: 30px;
    float: right;
    top: -90px;
}
.accept-group {
    position: relative;
    float: right;
    top: 10px;
    right: 70px;
    font-weight: 600;
}
.permit-group {
    font-weight: 600;
    line-height: 30px;
    left: -50px;
    position: relative;
}

label[for="caller"] {
    font-family: 'latolight';
    font-size: 13px;
    color: #a5a5a5;
    cursor: pointer;
}
.served_fleets {
    background-image: url(/build/img/ships.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-color: #1c627e47;
    display: block;
    position: absolute;
    top: 100px;
    height: 500px;
    right: 0;
}

.sip {
    right: -7px;
    width: 39px;
    position: absolute;
    float: right;
    display: inline-block;
    cursor: pointer;
}

.sip > i.fa.fa-phone {
    font-size: 26px;
    background-color: #4286b6;
    padding: 6px;
    color: #fafafa;
    box-shadow: -2px 0px 7px 0px #2e567270;
    border-radius: 5px 0 0 5px;
}

i.mapbut {
    background-color: #4286b6;
    padding: 6px;
    color: #fafafa;
    box-shadow: -2px 0px 7px 0px #2e567270;
    border-radius: 5px 0 0 5px;
    width: 33px;
    display: inline-block;
    height: 39px;
    background-image: url(/build/img/map.svg);
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
}
div#airport_map {
    width: 100%;
    position: relative;
    background: linear-gradient(#ffffff,#f1f1f1);
}

.vidos {
    width: 100%;
    display: block;
    position: absolute;
    top: 100px;
    border-radius: 20px !important;
    border: 4px solid #f0f0f0;
    box-shadow: 0 0 20px #0000005e;
    cursor: pointer;
}
.newwin:hover {
    box-shadow: 0 0 20px #28a6d461;
    transform: scale(1.1) translateY(-28px) translateX(223px);
}
.newwin {
    background: url(/img/newwin.svg);
    display: block;
    height: 17px;
    width: 17px;
    background-size: contain;
    position: absolute;
    transform: translateY(-30px) translateX(250px);
    transition: transform 0.5s ease-in-out;
}
.logo_desktop{
    max-height: 45px;margin: 5px 10px 10px 0;
}
.logo_mobile{
    display: none;
}

.viber-bot-container:first-child {
    display: block;
    padding: 0 0 20px 40px;
}
.viber-bot-container:first-child {
    white-space: nowrap;
}
.viber-bot-container img{
    height: 74px;
    vertical-align: top;
}

.viber-label span {
    font-weight: 500;
    color: #0597cd;
    font-size: 19px;
}
.viber-label .public {
    color: #7b519d7a;
    font-size: 19px;
    display: block;
}

.viber-label {
    display: inline-block;
    line-height: 25px;
    font-size: 25px;
}

.viber-label h3 {
    color: #7b519d7a;
}

.but-shelf {
    display: flex;
    width: 100%;
    flex-flow: row;
    justify-content: space-evenly;
}

.but-shelf .btn{
    min-width: 190px;
}

.icon-d{
    height: 200px;
}

