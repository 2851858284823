@font-face {
    font-family: 'latothin';
    src: url('../fonts/lato-thin.eot');
    src: url('../fonts/lato-thin.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-thin.woff') format('woff'),
         url('../fonts/lato-thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
/*1*/
@font-face {
    font-family: 'latolight';
    src: url('../fonts/lato-light.eot');
    src: url('../fonts/lato-light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-light.woff') format('woff'),
         url('../fonts/lato-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*1*/
@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular.eot');
    src: url('../fonts/lato-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-regular.woff') format('woff'),
         url('../fonts/lato-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*1*/
@font-face {
    font-family: 'latomedium';
    src: url('../fonts/lato-medium.eot');
    src: url('../fonts/lato-medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-medium.woff') format('woff'),
         url('../fonts/lato-medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
/*1*/
@font-face {
    font-family: 'latosemibold';
    src: url('../fonts/lato-semibold.eot');
    src: url('../fonts/lato-semibold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-semibold.woff') format('woff'),
         url('../fonts/lato-semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
/*1*/
@font-face {
    font-family: 'latobold';
    src: url('../fonts/lato-bold.eot');
    src: url('../fonts/lato-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-bold.woff') format('woff'),
         url('../fonts/lato-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
/*1*/
@font-face {
    font-family: 'latoheavy';
    src: url('../fonts/lato-heavy.eot');
    src: url('../fonts/lato-heavy.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-heavy.woff') format('woff'),
         url('../fonts/lato-heavy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoblack';
    src: url('../fonts/lato-black.eot');
    src: url('../fonts/lato-black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-black.woff') format('woff'),
         url('../fonts/lato-black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latothin_italic';
    src: url('../fonts/lato-thinitalic.eot');
    src: url('../fonts/lato-thinitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-thinitalic.woff') format('woff'),
         url('../fonts/lato-thinitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latolight_italic';
    src: url('../fonts/lato-lightitalic.eot');
    src: url('../fonts/lato-lightitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-lightitalic.woff') format('woff'),
         url('../fonts/lato-lightitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoitalic';
    src: url('../fonts/lato-italic.eot');
    src: url('../fonts/lato-italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-italic.woff') format('woff'),
         url('../fonts/lato-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*1*/
@font-face {
    font-family: 'latomedium_italic';
    src: url('../fonts/lato-mediumitalic.eot');
    src: url('../fonts/lato-mediumitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-mediumitalic.woff') format('woff'),
         url('../fonts/lato-mediumitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latosemibold_italic';
    src: url('../fonts/lato-semibolditalic.eot');
    src: url('../fonts/lato-semibolditalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-semibolditalic.woff') format('woff'),
         url('../fonts/lato-semibolditalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold_italic';
    src: url('../fonts/lato-bolditalic.eot');
    src: url('../fonts/lato-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-bolditalic.woff') format('woff'),
         url('../fonts/lato-bolditalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoheavy_italic';
    src: url('../fonts/lato-heavyitalic.eot');
    src: url('../fonts/lato-heavyitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-heavyitalic.woff') format('woff'),
         url('../fonts/lato-heavyitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoblack_italic';
    src: url('../fonts/lato-blackitalic.eot');
    src: url('../fonts/lato-blackitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-blackitalic.woff') format('woff'),
         url('../fonts/lato-blackitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latohairline';
    src: url('../fonts/lato-hairline.eot');
    src: url('../fonts/lato-hairline.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-hairline.woff') format('woff'),
         url('../fonts/lato-hairline.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latohairline_italic';
    src: url('../fonts/lato-hairlineitalic.eot');
    src: url('../fonts/lato-hairlineitalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-hairlineitalic.woff') format('woff'),
         url('../fonts/lato-hairlineitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*1*/
@font-face {
    font-family: 'Times-Italic';
    src: url('../fonts/Times-Italic.eot?#iefix') format('embedded-opentype'), 
       url('../fonts/Times-Italic.woff') format('woff'),
       url('../fonts/Times-Italic.ttf')  format('truetype'),
       url('../fonts/Times-Italic.svg#Times-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*1*/


@font-face {
    font-family: 'CGTimes-BoldItalic';
    src: url('../fonts/CGTimes-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/CGTimes-BoldItalic.woff') format('woff'),
       url('../fonts/CGTimes-BoldItalic.ttf')  format('truetype'), 
       url('../fonts/CGTimes-BoldItalic.svg#CGTimes-BoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}












